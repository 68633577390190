import useSWR from 'swr';
import { useEffect, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import get from 'lodash/get';
import cookie from 'js-cookie';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import { isBestSellerApplicable, isFeaturedProductApplicable, isPopularCategoryApplicable } from '../constants/feature';
import * as COOKIE_VARIABLES from '../constants/cookieVariables';
import * as ScheduleType from '../constants/scheduleWindow';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import { DEFAULT_ZIPCODE, DEFAULT_ZONE_ID } from '../constants/default';
import { GRASSDOOR } from '../constants/website';

import getFilterQueryString from '../helpers/getFilterQueryString';
import categoriesHelper from '../helpers/categoriesHelper';
import generateFilters from '../helpers/generateFilters';
import { setFirstBanner } from '../helpers/bannerHelper';
import getRouterPath from '../helpers/getRouterPath';

import storageService from '../services/storageService';

import useSocket from '../NetworkCall/Socket';

import axios from '../utils/ajax';

import appContext from '../Context/appContext';

import { updateAvailedOffer } from './cart';
import { asapBundleSlug } from '../constants/asapBundles';
import { useDispatch, useSelector } from 'react-redux';
import { setASAPProductList } from '../redux/slices/sidebar';

const token = cookie.get(COOKIE_VARIABLES.token) || '';

const fetcher = (url, customBaseUrl, hideError, customHeaders) =>
  axios({
    baseURL: customBaseUrl || process.env.NEXT_PUBLIC_SECURE_EC2_URL,
    url,
    method: 'GET',
    hideError,
    headers: customHeaders || {}
  }).then(res => res.data);

const fetcherProductDetail = (url, customBaseUrl) =>
  axios({ baseURL: customBaseUrl || process.env.NEXT_PUBLIC_SECURE_EC2_URL, url, method: 'GET' })
    .then(res => res.data)
    .catch(err => {
      if (typeof err === 'object' && !!Object.keys(err).length) {
        if (err.status === 404) {
          return 404;
        }
      }
      throw 'other error';
    });

let scheduledCategoriesGlobal = [];
const isGrassdoor = process.env.NEXT_PUBLIC_APP === GRASSDOOR;
const isDev = process.env.NEXT_PUBLIC_ENV === 'development';

export const useASAPShop = ({ asapCategoriesInitial = [], filters = [], intialAsapProductCount }) => {
  const {
    isLoggedIn,
    shouldFetchProduct,
    shouldFetchAsap,
    asapDriverID,
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)
  const dispatchRedux = useDispatch()
  const { cartData: { cart_items: cartItems = [] } = {} } = useSelector(state => state.cart) || {}

  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  let filterApplied = false;
  filters &&
    filters.forEach(element => {
      if (element.type === 'sort_key' && !element.values[0].checked) {
        filterApplied = true;
      }
      if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
        filterApplied = true;
      }
      if (element.type === 'search' && element.name == 'priceRange' && element.isSet) {
        filterApplied = true;
      }
    });

  const filtersQueryString = getFilterQueryString(filters);

  const options = {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    dedupingInterval: 8000
  };

  if (asapCategoriesInitial && !filterApplied) {
    options.initialData = { asapCategoriesInitial, intialAsapProductCount };
  }

  let { data, error, isValidating, mutate } = useSWR(
    shouldFetchProduct || shouldFetchAsap ? `/products/${postcode}?shop_menu=${false}&${filtersQueryString}${asapDriverID ? `&asap_driver_id=${asapDriverID}` : ``}` : null,
    fetcher,
    options
  );

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      asapCategories?.forEach(cat => {
        cat?.products?.forEach(product => {
          if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
            product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
          }
        });
      });
    }
  }, [favoriteStatusChangedForProduct]);

  const asapCategories = data && data.categories ? categoriesHelper(get(data, 'categories', [])) : asapCategoriesInitial || [];
  const asapCategoriesList = data?.categories && categoriesHelper(get(data, 'categories', []), false, false, true) || []

  const asapProductsCount = !error && !data ? null : data?.product_menu_count || data?.intialAsapProductCount || 0;

  if (!isLoggedIn) {
    updateLocalCartWithUpdatedOffer([...asapCategories], ScheduleType.ASAP, cartItems);
  }
  return {
    data: asapCategories,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    modifyAsap: !!(data && data.categories),
    asapProductsCount,
    mutate,
    asapCategoriesList
  };
};

export const useASAPBundelShop = ({ asapBundlesInitial, filters = [], intialAsapBundlesCount }) => {
  const {
    shouldFetchProduct,
    shouldFetchAsap,
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)

  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  const router = useRouter();

  const shopMenu = getRouterPath(router.pathname) === 'shop';

  let filterApplied = false;
  filters &&
    filters.forEach(element => {
      if (element.type === 'sort_key' && !element.values[0].checked) {
        filterApplied = true;
      }
      if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
        filterApplied = true;
      }
      if (element.type === 'search' && element.name == 'priceRange' && element.isSet) {
        filterApplied = true;
      }
    });

  const filtersQueryString = getFilterQueryString(filters);

  const options = {
    revalidateOnMount: true
  };

  if (asapBundlesInitial && !filterApplied) {
    options.initialData = { asapBundlesInitial, intialAsapBundlesCount };
  }

  const { data, error, isValidating, mutate } = useSWR(
    shouldFetchProduct || shouldFetchAsap ? `/products/bundle/${postcode}?shop_menu=${shopMenu}&${filtersQueryString}` : null,
    fetcher,
    options
  );

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      asapBundles?.forEach(cat => {
        cat?.products?.forEach(product => {
          if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
            product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
          }
        });
      });
    }
  }, [favoriteStatusChangedForProduct]);

  const asapBundles = data && !data.bundles ? data.asapBundlesInitial : categoriesHelper(get(data, 'bundles', []));
  const asapBundlesCount = !error && !data ? null : data?.count || data?.intialAsapBundlesCount || 0;

  return {
    data: asapBundles,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    asapBundlesCount
  };
};

export const useScheduleShop = ({ scheduleCategoriesInitial = [], filters = [], intialScheduleProductCount, page = 1 }) => {
  // const {
  //   state: {
  //     user: {
  //       isLoggedIn,
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE },
  //       shouldFetchProduct,
  //       shouldFetchSchedule
  //       // favoriteStatusChangedForProduct
  //     },
  //     cart: { cartData: { cart_items: cartItems = [] } = {} }
  //   }
  // } = useContext(appContext);
  const {
    isLoggedIn,
    deliveryDetails: { postcode = DEFAULT_ZIPCODE },
    shouldFetchProduct,
    shouldFetchSchedule
  } = useSelector(state => state.user)
  const cartItems = useSelector(state => state.cart?.cartData?.cart_items) || []
  const router = useRouter();
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  const shopMenu = getRouterPath(router.pathname) === 'shop';

  let filterApplied = false;
  filters &&
    filters.forEach(element => {
      if (element.type === 'sort_key' && !element.values[0].checked) {
        filterApplied = true;
      }
      if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
        filterApplied = true;
      }
    });

  const filtersQueryString = getFilterQueryString(filters);

  const options = {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    dedupingInterval: 8000
    // focusThrottleInterval: 100000
  };

  if (scheduleCategoriesInitial && !filterApplied) {
    options.initialData = { scheduleCategoriesInitial, intialScheduleProductCount };
  }

  const { data, error, isValidating, mutate } = useSWR(
    shouldFetchProduct || shouldFetchSchedule
      ? `/products/schedule/${postcode}?page=${page}&shop_menu=${shopMenu}${filterApplied ? '' : '&default=true'
      }&${filtersQueryString}`
      : null,
    fetcher,
    options
  );
  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      scheduleCategories?.forEach(cat => {
        cat?.products?.forEach(product => {
          if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
            product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
          }
        });
      });
    }
  }, [favoriteStatusChangedForProduct]);

  let scheduleCategories =
    data && data?.categories ? categoriesHelper(get(data, 'categories', [])) : scheduleCategoriesInitial || [];
  const scheduledProductsCount = !error && !data ? null : data?.product_menu_count || data?.intialScheduleProductCount || 0;
  let scheduleCategoriesList = data?.categories && categoriesHelper(get(data, 'categories', []), false, false, true) || []

  if (!isLoggedIn) {
    updateLocalCartWithUpdatedOffer([...scheduleCategories], ScheduleType.Scheduled, cartItems);
  }

  if (page === 1) {
    scheduledCategoriesGlobal = scheduleCategories;
  }
  if (page === 2) {
    scheduleCategories = [...scheduledCategoriesGlobal, ...scheduleCategories];
  }
  // asapCategories = uniqBy(asapCategories, 'id'); // To filter out duplicate popular and recommended categories
  return {
    data: scheduleCategories,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    modifySchedule: !!(data && data.categories),
    schedule_product_menu_count: data?.product_menu_count,
    scheduledProductsCount,
    mutate,
    scheduleCategoriesList
  };
};

export const useASAPBestSellerShop = ({ initialAsapPopularProducts, filters = [], slug = '', isDealsPage = false }) => {
  // const {
  //   state: {
  //     user: {
  //       shouldFetchProduct,
  //       shouldFetchAsap,
  //       deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    shouldFetchProduct,
    shouldFetchAsap,
    deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
  } = useSelector(state => state.user)

  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);
  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  const filtersQueryString = getFilterQueryString(filters, true);

  const options = {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    initialData: initialAsapPopularProducts ? { initialAsapPopularProducts } : undefined
  };

  // isDev used to prevent pages from breaking because of API failure in dev env
  // Remove condition if changes wrt this API are being tested on dev
  // Revert back once done

  const { data, error, isValidating, mutate } =
    useSWR(
      isBestSellerApplicable && (shouldFetchProduct || shouldFetchAsap || !initialAsapPopularProducts)
        ? [
          `/recommend/top-sellers/${slug ? `${slug}/${ASAP}` : ASAP}/${isDev ? DEFAULT_ZONE_ID : zoneId}?${filtersQueryString}`,
          process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL,
          true
        ]
        : null,
      fetcher,
      options
    ) || {};

  useEffect(() => {
    if (isBestSellerApplicable) {
      getUpdatedZone(() => {
        mutate();
      });
    }

    return () => {
      if (isBestSellerApplicable) {
        removeListnerUpdatedZone();
      }
    };
  }, []);

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      Array.isArray(modifiedData) && modifiedData?.forEach(cat => {
        Array.isArray(cat?.products) && cat?.products?.forEach(product => {
          if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
            product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
          }
        });
      });
    }
  }, [favoriteStatusChangedForProduct]);

  let modifiedData = null;
  if (!data) {
    modifiedData = null;
  } else if (data.categories) {
    modifiedData = isDealsPage ? data.categories : categoriesHelper(data.categories, true, true);
  } else {
    modifiedData = data?.initialAsapPopularProducts || [];
  }

  return {
    data: modifiedData,
    isLoading: isBestSellerApplicable && !error && !data,
    isError: isBestSellerApplicable && error,
    isValidating: isBestSellerApplicable && isValidating
  };
};

export const useScheduleBestSellerShop = ({ initialSchedulePopularProducts, filters = [], slug = '', isDealsPage = false }) => {
  // const {
  //   state: {
  //     user: {
  //       shouldFetchProduct,
  //       shouldFetchSchedule,
  //       deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    shouldFetchProduct,
    shouldFetchSchedule,
    deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
  } = useSelector(state => state.user)
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);
  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  const filtersQueryString = getFilterQueryString(filters, true);

  const options = {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    initialData: initialSchedulePopularProducts ? { initialSchedulePopularProducts } : undefined
  };

  // isDev used to prevent pages from breaking because of API failure in dev env
  // Remove condition if changes wrt this API are being tested on dev
  // Revert back once done\

  const { data, error, isValidating, mutate } = useSWR(
    isBestSellerApplicable && (shouldFetchProduct || shouldFetchSchedule || !initialSchedulePopularProducts)
      ? [
        `/recommend/top-sellers/${slug ? `${slug}/${SCHEDULE}` : SCHEDULE}/${isDev ? DEFAULT_ZONE_ID : zoneId
        }?${filtersQueryString}`,
        process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL,
        true
      ]
      : null,
    fetcher,
    options
  );

  useEffect(() => {
    if (isBestSellerApplicable) {
      getUpdatedZone(() => {
        mutate();
      });
    }

    return () => {
      if (isBestSellerApplicable) {
        removeListnerUpdatedZone();
      }
    };
  }, []);

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      Array.isArray(modifiedData) && modifiedData?.forEach(cat => {
        Array.isArray(cat?.products) && cat?.products?.forEach(product => {
          if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
            product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
          }
        });
      });
    }
  }, [favoriteStatusChangedForProduct]);

  let modifiedData = null;
  if (!data) {
    modifiedData = null;
  } else if (data?.categories) {
    modifiedData = isDealsPage ? data.categories : categoriesHelper(data.categories, true, true);
  } else {
    modifiedData = data?.initialSchedulePopularProducts || [];
  }

  return {
    data: modifiedData,
    isLoading: isBestSellerApplicable && !error && !data,
    isError: isBestSellerApplicable && error,
    isValidating: isBestSellerApplicable && isValidating
  };
};

// export const useASAPRecommendedShop = ({ initialAsapRecommendedCategories, filters = [], recommendedProductsError = false }) => {
//   const {
//     state: {
//       user: {
//         isLoggedIn,
//         shouldFetchProduct,
//         shouldFetchAsap,
//         deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
//       }
//     }
//   } = useContext(appContext);

//   const router = useRouter();

//   // const zoneId = initialLocationAddress.zone_id;

//   let filterApplied = false;
//   filters &&
//     filters.forEach(element => {
//       if (element.type === 'sort_key' && !element.values[0].checked) {
//         filterApplied = true;
//       }
//       if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
//         filterApplied = true;
//       }
//     });

//   const filtersQueryString = getFilterQueryString(filters);

//   const options = {
//     revalidateOnMount: true
//   };

//   if (initialAsapRecommendedCategories) {
//     options.initialData = { initialAsapRecommendedCategories };
//   }

//   const { data, error, isValidating, mutate } = useSWR(
//     isLoggedIn && (recommendedProductsError || isEmpty(recommendedProductsError)) && (shouldFetchProduct || shouldFetchAsap || !initialAsapRecommendedCategories)
//       ? [`/recommend/products/${ASAP}/${zoneId}?shop_menu=true&${filtersQueryString}`, process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL, true] : null,
//     fetcher,
//     options
//   );

//   const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

//   useEffect(() => {
//     getUpdatedZone(() => {
//       mutate();
//     });
//     return () => {
//       removeListnerUpdatedZone();
//     };
//   });

//   let asapRecommendedCategories = [];

//   asapRecommendedCategories = data && !data.categories ? data.initialAsapRecommendedCategories : categoriesHelper(data?.categories || [], true);

//   return {
//     data: asapRecommendedCategories,
//     isLoading: !error && !data,
//     isError: error,
//     isValidating,
//     modifyAsap: !!(data && data.data)
//   };
// };

// export const useScheduleRecommendedShop = ({ initialScheduleRecommendedCategories, filters = [], recommendedProductsError = false }) => {
//   const {
//     state: {
//       user: {
//         isLoggedIn,
//         shouldFetchProduct,
//         shouldFetchSchedule,
//         deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
//       }
//     }
//   } = useContext(appContext);

//   const router = useRouter();

//   // const zoneId = initialLocationAddress.zone_id;

//   let filterApplied = false;
//   filters &&
//     filters.forEach(element => {
//       if (element.type === 'sort_key' && !element.values[0].checked) {
//         filterApplied = true;
//       }
//       if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
//         filterApplied = true;
//       }
//     });

//   const filtersQueryString = getFilterQueryString(filters);

//   const options = {
//     revalidateOnMount: true
//   };

//   if (initialScheduleRecommendedCategories) {
//     options.initialData = { initialScheduleRecommendedCategories };
//   }

//   const { data, error, isValidating, mutate } = useSWR(
//     isLoggedIn && (recommendedProductsError || isEmpty(recommendedProductsError)) && (shouldFetchProduct || shouldFetchSchedule || !initialScheduleRecommendedCategories)
//       ? [`/recommend/products/${SCHEDULE}/${zoneId}?shop_menu=true&${filtersQueryString}`, process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL, true] : null,
//     fetcher,
//     options
//   )

//   const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

//   useEffect(() => {
//     getUpdatedZone(() => {
//       mutate();
//     });
//     return () => {
//       removeListnerUpdatedZone();
//     };
//   });

//   let scheduleRecommendedCategories = [];
//   scheduleRecommendedCategories = data && !data.categories ? data.initialScheduleRecommendedCategories : categoriesHelper(data?.categories || [], true);

//   return {
//     data: scheduleRecommendedCategories,
//     isLoading: !error && !data,
//     isError: error,
//     isValidating,
//     modifySchedule: !!(data && data.data)
//   };
// };

const updateLocalCartWithUpdatedOffer = (categories, menuType, cartItems) => {
  Array.isArray(categories) && categories?.forEach(category => {
    Array.isArray(category?.products) && category?.products?.forEach(product => {
      const cartProduct = cartItems.find(item => item.product_id === product.product_id && item.menu === menuType);
      if (cartProduct) {
        const updatedProduct = updateAvailedOffer({ ...cartProduct, offer_product: product.offer_product });
        storageService.updateCart({
          id: product.product_id,
          quantity: cartProduct.quantity,
          offerProduct: updatedProduct?.offer_product
        });
      }
    });
  });
};

export const useGetTabFromProduct = data => {
  let tab;
  if (data?.menu) {
    tab = data?.menu === ScheduleType.Scheduled ? SCHEDULE : ASAP;
  } else {
    tab = data?.list === 'Schedule List' ? SCHEDULE : ASAP;
  }
  return tab;
};

export const useProductCount = () => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)
  const { data, error } = useSWR(`/products/count/${postcode}`, fetcher);
  const scheduledProductsCount = get(data, 'schedule_product_count', 0);
  const asapProductsCount = get(data, 'asap_product_count', 0);
  const asapFilterData = get(data, 'filterwise_product_count_asap', 0);
  const scheduleFilterData = get(data, 'filterwise_product_count_schedule', 0);
  return {
    data: { scheduledProductsCount, asapProductsCount, asapFilterData, scheduleFilterData },
    isLoading: !error && !data,
    isError: error
  };
};

export const useAsapProductsCategory = ({
  slug,
  asapProductsCategoryInitial,
  initialAsapCount,
  initialAsapProductsCategoryDetails,
  filters,
  isDealsPage = false,
  isShopPage = false
}) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  // The api shouldn't be called if the page is not "deals" page and slug is not "asap-bundles"
  if (!isShopPage && !isDealsPage && slug !== asapBundleSlug) {
    return {
      data: { currentCategory: null, asapCount: null },
      isLoading: false,
      isError: false,
      isValidating: false
    };
  }

  let filterApplied = false;
  filters &&
    filters.forEach(element => {
      if (element.type === 'sort_key' && !element.values[0].checked) {
        filterApplied = true;
      }
      if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
        filterApplied = true;
      }
    });

  const filtersQueryString = getFilterQueryString(filters);

  const options = {
    revalidateOnMount: true
  };
  if (asapProductsCategoryInitial && !isEmpty(asapProductsCategoryInitial) && !filterApplied) {
    options.initialData = { asapProductsCategoryInitial, initialAsapCount, initialAsapProductsCategoryDetails, dontModify: true };
  }

  const url =
    slug === asapBundleSlug || isDealsPage
      ? `/products/bundle/${postcode}` // Calling bundles api if its the deals page or the slug is "asap-bundles"
      : `/categories/${slug}/${postcode}`; // Otherwise calling the categories api
  const { data, error, isValidating, mutate } = useSWR(`${url}?${filtersQueryString}`, fetcher, options);

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });

  let currentCategory;
  let asapCount;

  if (data && data.dontModify) {
    currentCategory = data.asapProductsCategoryInitial;
    asapCount = data.initialAsapCount;
  } else {
    if (isDealsPage) {
      currentCategory = data?.bundles || [];
      asapCount = data?.count || null;
    } else {
      const categoryData = get(data, 'categoryDetails', {});
      const category =
        slug === asapBundleSlug ? categoriesHelper(data?.bundles || []) : categoriesHelper(get(data, 'categories', []));
      currentCategory = category.length ? category[0] : {};
      currentCategory.desktopImageURL =
        categoryData && categoryData.desktop && categoryData.desktop.imagePath ? categoryData.desktop.imagePath : null;
      currentCategory.mobileImageURL =
        categoryData && categoryData.mobile && categoryData.mobile.imagePath ? categoryData.mobile.imagePath : null;
      currentCategory.header = categoryData && categoryData.header ? categoryData.header : '';
      asapCount = slug === asapBundleSlug ? data?.count : get(data, 'asap_product_count', null);
    }
  }

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      currentCategory?.products?.forEach(product => {
        if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
          product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
        }
      });
    }
  }, [favoriteStatusChangedForProduct]);

  return {
    data: { currentCategory, asapCount },
    isLoading: !error && !data,
    isError: error,
    isValidating
  };
};

export const useLocationProductsCategory = ({ slug, location, staticData }) => {
  const { data, error, isValidating, mutate } = useSWR(`/products/schedule/${slug}/location/${location}`, fetcher, {
    initialData: { staticData, dontModify: true },
    revalidateOnMount: true
  });

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });

  let currentCategory;

  if (data.dontModify) {
    currentCategory = data.asapProductsCategoryInitial;
  } else {
    const category = categoriesHelper(get(data, 'categories', []));
    currentCategory = category.length ? category[0] : {};
  }

  return {
    data: { currentCategory },
    isLoading: !error && !data,
    isError: error,
    isValidating
  };
};

export const useScheduleProductsCategory = ({
  slug,
  scheduleProductsCategoryInitial,
  initialScheduleCount,
  initialScheduleProductsCategoryDetails,
  filters
}) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  let filterApplied = false;
  filters &&
    filters.forEach(element => {
      if (element.type === 'sort_key' && !element.values[0].checked) {
        filterApplied = true;
      }
      if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
        filterApplied = true;
      }
    });

  const filtersQueryString = getFilterQueryString(filters);

  const options = {
    revalidateOnMount: true
  };

  if (scheduleProductsCategoryInitial && !isEmpty(scheduleProductsCategoryInitial) && !filterApplied) {
    options.initialData = {
      scheduleProductsCategoryInitial,
      initialScheduleCount,
      initialScheduleProductsCategoryDetails,
      dontModify: true
    };
  }

  const url = slug == asapBundleSlug ? `/products/bundle/${postcode}` : `/products/schedule/${slug}/${postcode}`;
  const { data, error, isValidating, mutate } = useSWR(`${url}?${filtersQueryString}`, fetcher, options);

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });

  let currentCategory;
  let scheduleCount;
  if (data && data.dontModify) {
    currentCategory = data.scheduleProductsCategoryInitial;
    scheduleCount = data.initialScheduleCount;
  } else {
    const category =
      slug == asapBundleSlug
        ? []
        : data?.dontModify
          ? data.scheduleProductsCategoryInitial
          : categoriesHelper(get(data, 'categories', []));
    currentCategory = category.length ? category[0] : null;
    scheduleCount = slug == asapBundleSlug ? 0 : get(data, 'schedule_product_count', null);
  }

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      currentCategory?.products?.forEach(product => {
        if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
          product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
        }
      });
    }
  }, [favoriteStatusChangedForProduct]);

  return {
    data: { currentCategory, scheduleCount },
    isLoading: !error && !data,
    isError: error,
    isValidating
  };
};

export const useProductDetails = ({ slug, tab, initialProductDetails }) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)

  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  const options = {
    revalidateOnMount: true
  };
  if (initialProductDetails) {
    options.initialData = { initialProductDetails, dontModify: true };
  }
  let scheduleType;
  if (tab === SCHEDULE) {
    scheduleType = ScheduleType.Scheduled;
  } else if (tab === ASAP) {
    scheduleType = ScheduleType.ASAP;
  } else {
    scheduleType = ScheduleType.No_Inventory;
  }
  const { data, error, isValidating, mutate } = useSWR(
    `/products/${slug}/${postcode}?schedule_type=${scheduleType}`,
    fetcherProductDetail,
    options
  );

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      if (productDetails?.product_id == favoriteStatusChangedForProduct.productId) {
        productDetails.is_favourite = favoriteStatusChangedForProduct.is_favourite;
      }
    }
  }, [favoriteStatusChangedForProduct]);

  const productDetails = data && data.dontModify ? initialProductDetails : get(data, 'categories', {});
  return {
    data: { productDetails },
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
};

export const useBundleDetails = ({ slug, initialBundleDetails }) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  const options = {
    revalidateOnMount: true
  };

  if (initialBundleDetails) {
    options.initialData = { initialBundleDetails, dontModify: true };
  }

  const { data, error, isValidating, mutate } = useSWR(`/products/${postcode}/bundles/${slug}/`, fetcher, options);

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      if (bundleDetails?.bundle_id == favoriteStatusChangedForProduct.productId) {
        bundleDetails.is_favourite = favoriteStatusChangedForProduct.is_favourite;
      }
    }
  }, [favoriteStatusChangedForProduct]);

  const bundleDetails = data && data.dontModify ? initialBundleDetails : get(data, 'data.details', {});

  return {
    data: { bundleDetails },
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
};

export const useBrand = ({ slug, initialBrandData, filters = [] }) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  let filterApplied = false;
  filters &&
    filters.forEach(element => {
      if (element.type === 'sort_key' && !element.values[0].checked) {
        filterApplied = true;
      }
      if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
        filterApplied = true;
      }
    });

  const filtersQueryString = getFilterQueryString(filters);

  const groupingReq =
    process.env.NEXT_PUBLIC_APP === GRASSDOOR || process.env.NEXT_PUBLIC_DEFAULT_SITES
      ? '&group_categories=false'
      : '&group_categories=true';

  const options = {};

  if (initialBrandData && !filterApplied && slug == initialBrandData.brand_slug) {
    options.initialData = { initialBrandData, dontModify: true };
  }

  const { data, error, isValidating, mutate } = useSWR(
    slug ? [`/brands/${slug}/${postcode}?${filtersQueryString}${groupingReq}`, null, true] : null,
    fetcher,
    options
  );

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      [brandData?.asap_categories, brandData?.schedule_categories]?.forEach(productCategory => {
        productCategory?.forEach(cat => {
          cat?.products?.forEach(product => {
            if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
              product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
            }
          });
        });
      });
    }
  }, [favoriteStatusChangedForProduct]);

  const brandData = data && data.dontModify ? initialBrandData : get(data, 'data', {});

  return {
    data: { brandData },
    mutate,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    modifyCount: true
  };
};

export const useAsapSubcategory = ({
  slug,
  asapCategoriesInitial,
  filters = [],
  initialAsapCount,
  initialAsapCategoryDetails,
  isDealsPage = false
}) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE },
  //       shouldFetchAsap
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE },
    shouldFetchAsap
  } = useSelector(state => state.user)
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  let filterApplied = false;
  filters &&
    filters.forEach(element => {
      if (element.type === 'sort_key' && !element.values[0].checked) {
        filterApplied = true;
      }
      if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
        filterApplied = true;
      }
    });

  const filtersQueryString = getFilterQueryString(filters);

  const options = {
    revalidateOnMount: true
  };

  if (asapCategoriesInitial && !filterApplied) {
    options.initialData = { asapCategoriesInitial, initialAsapCount, initialAsapCategoryDetails };
  }

  let tpost = postcode;
  if (postcode == '00000' && slug == 'deals') {
    tpost = '90401';
  }

  let withoutCategory = '';
  if (isDealsPage) {
    withoutCategory = '&without_category=1';
  }

  const { data, error, isValidating, mutate } = useSWR(
    shouldFetchAsap ? `/full_shop_category/asap/${slug}/${tpost}?${filtersQueryString}${withoutCategory}` : null,
    fetcher,
    options
  );

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });

  let asapCategories = data?.asapCategoriesInitial || [];
  if (data?.categories && data.categories.length !== 0) {
    asapCategories = isDealsPage ? data.categories : categoriesHelper(data?.categories || []);
  }
  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      asapCategories?.forEach(cat => {
        cat?.products?.forEach(product => {
          if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
            product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
          }
        });
      });
    }
  }, [favoriteStatusChangedForProduct]);

  const asapCount = !error && !data ? null : data?.initialAsapCount || data?.product_menu_count || 0;
  const asapCategoryDetails = data?.initialAsapCategoryDetails || data?.categoryDetails || {};
  const asapSecondaryCategoryFAQ = data?.secondary_category_FAQ || [];
  return {
    data: { asapCategories, asapCount, asapCategoryDetails, asapSecondaryCategoryFAQ },
    isLoading: !error && !data,
    isError: error,
    isValidating
  };
};

export const useScheduleSubcategory = ({
  slug,
  scheduleCategoriesInitial,
  filters = [],
  initialScheduleCount,
  initialScheduleCategoryDetails,
  isDealsPage = false
}) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE },
  //       shouldFetchSchedule
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE },
    shouldFetchSchedule
  } = useSelector(state => state.user)
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  let filterApplied = false;
  filters &&
    filters.forEach(element => {
      if (element.type === 'sort_key' && !element.values[0].checked) {
        filterApplied = true;
      }
      if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
        filterApplied = true;
      }
    });

  const filtersQueryString = getFilterQueryString(filters, false, isDealsPage);

  const options = {
    revalidateOnMount: true
  };

  if (scheduleCategoriesInitial && !filterApplied) {
    options.initialData = { scheduleCategoriesInitial, initialScheduleCount, initialScheduleCategoryDetails };
  }

  let tpost = postcode;
  if (postcode == '00000' && slug == 'deals') {
    tpost = '90401';
  }

  let withoutCategory = '';
  if (isDealsPage) {
    withoutCategory = '&without_category=1';
  }

  const { data, error, isValidating, mutate } = useSWR(
    shouldFetchSchedule ? `/full_shop_category/schedule/${slug}/${tpost}?${filtersQueryString}${withoutCategory}` : null,
    fetcher,
    options
  );

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });
  let scheduleCategories = data?.scheduleCategoriesInitial || [];
  if (data?.categories && data.categories.length !== 0) {
    scheduleCategories = isDealsPage ? data.categories : categoriesHelper(data?.categories || []);
  }
  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      Array.isArray(scheduleCategories) && scheduleCategories?.forEach(cat => {
        Array.isArray(cat?.products) && cat?.products?.forEach(product => {
          if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
            product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
          }
        });
      });
    }
  }, [favoriteStatusChangedForProduct]);

  const scheduleCount = !error && !data ? null : data?.initialScheduleCount || data?.product_menu_count || 0;
  const scheduleCategoryDetails = data?.initialScheduleCategoryDetails || data?.categoryDetails || {};
  const scheduleSecondaryCategoryFAQ = data?.secondary_category_FAQ || [];
  return {
    data: { scheduleCategories, scheduleCount, scheduleCategoryDetails, scheduleSecondaryCategoryFAQ },
    isLoading: !error && !data,
    isError: error,
    isValidating
  };
};

export const useShopFilters = ({ initialFilters }) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE },
  //       shouldFetchProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE },
    shouldFetchProduct
  } = useSelector(state => state.user)

  function generateShopFilters(filterResponse, priceRange) {
    const fullShopCategories = categoriesHelper(get(filterResponse, 'full_shop_categories', []));
    const productStrainTypes = categoriesHelper(get(filterResponse, 'product_strain_types', []));
    const brands = categoriesHelper(get(filterResponse, 'brands', []));
    const deviceTypes = get(filterResponse, 'device_types', []);

    return generateFilters({ fullShopCategories, productStrainTypes, brands, deviceTypes, priceRange });
  }

  const options = {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: true
  };

  if (initialFilters) {
    options.initialData = { initialFilters, dontModify: true };
  }

  const { data, error, isValidating, mutate } = useSWR(
    shouldFetchProduct || !initialFilters ? `/categories/all_categories/${postcode}` : null,
    fetcher,
    options
  );

  const filterData = data && data.dontModify ? initialFilters : data;

  const productsError = false;
  const products = [];
  const priceRange = {};

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });

    return () => {
      removeListnerUpdatedZone();
    };
  });

  const filters = filterData && priceRange ? generateShopFilters(filterData, priceRange) : null;

  return {
    data: filters,
    isLoading: !error && !productsError && !data && !products,
    isError: error,
    isValidating
  };
};

export const useFilters = ({ param, slug, isDealsPage = false }) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE },
  //       isLoggedIn
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE },
    isLoggedIn
  } = useSelector(state => state.user)

  function generateShopFilters(filterResponse) {
    let fullShopCategories = [];
    let productStrainTypes = [];
    let brands = [];

    switch (param) {
      case 'brand_slug':
        fullShopCategories = categoriesHelper(get(filterResponse, 'full_shop_categories', []));
        productStrainTypes = categoriesHelper(get(filterResponse, 'product_strain_types', []));
        break;

      case 'category_slug':
        productStrainTypes = categoriesHelper(get(filterResponse, 'product_strain_types', []));
        brands = categoriesHelper(get(filterResponse, 'brands', []));
        if (isDealsPage) {
          fullShopCategories = categoriesHelper(get(filterResponse, 'full_shop_categories', []));
        }
        break;

      case 'shop_slug':
        productStrainTypes = categoriesHelper(get(filterResponse, 'product_strain_types', []));
        brands = categoriesHelper(get(filterResponse, 'brands', []));
        break;
      case 'saved_for_later':
      case 'favourite':
        fullShopCategories = categoriesHelper(get(filterResponse, 'full_shop_categories', []));
        productStrainTypes = categoriesHelper(get(filterResponse, 'product_strain_types', []));
        brands = categoriesHelper(get(filterResponse, 'brands', []));
        break;

      default:
        break;
    }

    const deviceTypes = get(filterResponse, 'device_types', []);
    return generateFilters({ fullShopCategories, productStrainTypes, deviceTypes, brands, isDealsPage });
  }

  function getProducts() {
    const favouriteItems = storageService.getFavourites();
    const saveForLaterItems = storageService.getSaveForLaterItems();

    const items = param === 'favourite' ? favouriteItems : saveForLaterItems;

    let product_id = [];
    let bundle_id = [];

    items.forEach(item => {
      if (item?.bundle_id) {
        bundle_id.push(item.bundle_id);
      } else if (item?.product_details_id) {
        product_id.push(item.product_details_id);
      }
    });

    let productString = '';

    if (product_id?.length > 0) productString = productString + `&product_id=${JSON.stringify(product_id)}`;
    if (bundle_id?.length > 0) productString = productString + `&bundle_id=${JSON.stringify(bundle_id)}`;

    return productString;
  }

  let isProductsRequired = !isLoggedIn && (param === 'favourite' || param === 'saved_for_later');

  const { data, error, isValidating, mutate } = useSWR(
    `categories/all_categories/${postcode}?${param}=${slug}${isProductsRequired ? `${getProducts()}` : ''}`,
    fetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: false
    }
  );

  const filters = data ? generateShopFilters(data) : null;
  return {
    data: filters,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
};

export const useBannerImages = ({ initialBannerData }) => {
  // const {
  //   state: {
  //     user: { shouldFetchProduct }
  //   }
  // } = useContext(appContext);
  const {
    shouldFetchProduct
  } = useSelector(state => state.user)

  const { data, mutate } = useSWR(shouldFetchProduct || !initialBannerData ? `/cms/carousel_details` : null, fetcher, {
    initialData: { initialBannerData, dontModify: true },
    revalidateOnMount: true,
    revalidateOnFocus: true
  });

  const seoData = get(data, 'data', []);
  const bannerData = seoData && seoData.banner_images ? seoData.banner_images : {};
  const bannerDataWithFirstBannerSet = setFirstBanner(bannerData);

  const finalBannerData = data.dontModify ? initialBannerData : bannerDataWithFirstBannerSet;

  return {
    data: finalBannerData,
    mutate
  };
};

export const useFullCategoryList = ({ initialFullCategoriesList, revalidate = false } = {}) => {
  // const {
  //   state: {
  //     user: { shouldFetchProduct }
  //   }
  // } = useContext(appContext);
  const {
    shouldFetchProduct
  } = useSelector(state => state.user)

  const options = { revalidateOnMount: revalidate };

  if (initialFullCategoriesList) {
    options.initialData = { initialFullCategoriesList, dontModify: true };
  }

  const { data, error, mutate } = useSWR(
    shouldFetchProduct || !initialFullCategoriesList ? `/pre_fetch/logo` : null,
    fetcher,
    options
  );

  const finalFullCategoriesList = data && data.dontModify ? initialFullCategoriesList : data;
  return {
    data: finalFullCategoriesList,
    isLoading: !error && !data,
    mutate
  };
};

export const useTopBrandsList = ({ initialTopBrandsList, revalidate = false }) => {
  const router = useRouter();
  if (router.pathname === '/') {
    return {
      data: initialTopBrandsList
    };
  }
  const options = { revalidateOnMount: revalidate };

  if (initialTopBrandsList) {
    options.initialData = { initialTopBrandsList, dontModify: true };
  }

  const { data, error, mutate } = useSWR(`/brands/top_brands`, fetcher, options);
  const finalTopBrandsList = data && data.dontModify ? initialTopBrandsList : data?.data;
  return {
    data: finalTopBrandsList,
    isLoading: !error && !data,
    mutate
  };
};

export const usePromoCodesList = () => {
  const options = { revalidateOnMount: true };

  const { data, error, mutate } = useSWR(`customers/available_promo_codes`, fetcher, options);

  const finalTopBrandsList = data?.data?.availablePromoCodes;
  return {
    data: finalTopBrandsList,
    isLoading: !error && !data,
    mutate
  };
};

export const useAllBrandsList = ({ initialAllBrandsList }) => {
  const options = { revalidateOnMount: true };

  if (initialAllBrandsList) {
    options.initialData = { initialAllBrandsList, dontModify: true };
  }

  const { data, error, mutate } = useSWR(`/brands/all_brands`, fetcher, options);

  const finalAllBrandsList = data && data.dontModify ? initialAllBrandsList : data?.brands;
  return {
    data: finalAllBrandsList,
    isLoading: !error && !data,
    mutate
  };
};

export const useCityListing = ({ initialCityList }) => {
  const options = { revalidateOnMount: true };

  if (initialCityList) {
    options.initialData = { initialCityList, dontModify: true };
  }

  const { data, error, mutate } = useSWR(`/location/city`, fetcher, options);

  const finalCityList = [];

  if (data?.dontModify) {
    finalCityList.push(...initialCityList);
  } else if (data?.data) {
    finalCityList.push(...data.data);
  }

  finalCityList.sort((a, b) => {
    if (a.modified < b.modified) {
      return -1;
    }

    if (a.modified > b.modified) {
      return 1;
    }

    return 0;
  });

  return {
    data: finalCityList,
    isLoading: !error && !data,
    mutate
  };
};

export const useBundlesOfCategory = () => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)
  const { data, mutate } = useSWR(`/products/${postcode}/bundles`, fetcher);

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  useEffect(() => {
    getUpdatedZone(() => {
      mutate();
    });
    return () => {
      removeListnerUpdatedZone();
    };
  });

  const bundleData = categoriesHelper(get(data, 'categories', []));
  const currentBundles = bundleData.length ? bundleData : null;

  return {
    data: currentBundles
  };
};

export const useAsapPopularCategories = ({ initialAsapPopularCategories, initialAsapProductCount }) => {
  // const {
  //   state: {
  //     user: {
  //       shouldFetchAsap,
  //       shouldFetchProduct,
  //       deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    deliveryDetails: { zoneId = DEFAULT_ZONE_ID },
    shouldFetchAsap,
    shouldFetchProduct
  } = useSelector(state => state.user)
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  const options = {
    revalidateOnMount: true,
    initialData: initialAsapPopularCategories ? { initialAsapPopularCategories, initialAsapProductCount } : undefined
  };

  // isDev used to prevent pages from breaking because of API failure in dev env
  // Remove condition if changes wrt this API are being tested on dev
  // Revert back once done\\

  const { data, isValidating, error, mutate } =
    useSWR(
      isPopularCategoryApplicable && (shouldFetchAsap || shouldFetchProduct)
        ? [
          `/category-hub/top-sellers/${ASAP}/${isDev ? DEFAULT_ZONE_ID : zoneId}?sort_key=deals_first&order=asc`,
          process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL,
          true
        ]
        : null,
      fetcher,
      options
    ) || {};

  useEffect(() => {
    if (isPopularCategoryApplicable) {
      getUpdatedZone(() => {
        mutate();
      });
    }

    return () => {
      if (isPopularCategoryApplicable) {
        removeListnerUpdatedZone();
      }
    };
  }, []);

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      Array.isArray(modifiedData) && modifiedData?.forEach(cat => {
        Array.isArray(cat?.products) && cat?.products?.forEach(product => {
          if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
            product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
          }
        });
      });
    }
  }, [favoriteStatusChangedForProduct]);

  let modifiedData = null;
  if (!data) {
    modifiedData = null;
  } else if (data?.categories) {
    modifiedData = categoriesHelper(data.categories, true);
  } else {
    modifiedData = data?.initialAsapPopularCategories || [];
  }
  return {
    data: modifiedData,
    asapProductsCount: data?.product_menu_count || data?.initialAsapProductCount,
    isLoading: !error && !data,
    isError: error,
    isValidating
  };
};

export const useSchedulePopularCategories = ({ initialSchedulePopularCategories, initialScheduleProductCount }) => {
  // const {
  //   state: {
  //     user: {
  //       shouldFetchSchedule,
  //       shouldFetchProduct,
  //       deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    shouldFetchProduct,
    shouldFetchSchedule,
    deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
  } = useSelector(state => state.user)
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  const options = {
    revalidateOnMount: true
  };

  if (initialSchedulePopularCategories) {
    options.initialData = { initialSchedulePopularCategories, initialScheduleProductCount };
  }

  // isDev used to prevent pages from breaking because of API failure in dev env
  // Remove condition if changes wrt this API are being tested on dev
  // Revert back once done

  const { data, isValidating, error, mutate } = useSWR(
    isPopularCategoryApplicable && (shouldFetchSchedule || shouldFetchProduct)
      ? [
        `/category-hub/top-sellers/${SCHEDULE}/${isDev ? DEFAULT_ZONE_ID : zoneId}?sort_key=deals_first&order=asc`,
        process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL,
        true
      ]
      : null,
    fetcher,
    options
  );

  useEffect(() => {
    if (isPopularCategoryApplicable) {
      getUpdatedZone(() => {
        mutate();
      });
    }

    return () => {
      if (isPopularCategoryApplicable) {
        removeListnerUpdatedZone();
      }
    };
  }, []);

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      Array.isArray(modifiedData) && modifiedData?.forEach(cat => {
        Array.isArray(cat?.products) && cat?.products?.forEach(product => {
          if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
            product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
          }
        });
      });
    }
  }, [favoriteStatusChangedForProduct]);

  let modifiedData = null;
  if (!data) {
    modifiedData = null;
  } else if (data?.categories) {
    modifiedData = categoriesHelper(data.categories, true);
  } else {
    modifiedData = data?.initialSchedulePopularCategories || [];
  }

  return {
    data: modifiedData,
    scheduledProductsCount: !error && !data ? null : data?.product_menu_count || data?.initialScheduleProductCount || 0,
    isLoading: !error && !data,
    isError: error,
    isValidating
  };
};

export const useAsapFeaturedShop = props => {
  const { initialAsapFeaturedProducts } = props;

  // const {
  //   state: {
  //     user: {
  //       shouldFetchAsap,
  //       shouldFetchProduct,
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    shouldFetchProduct,
    shouldFetchAsap,
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  const options = {
    revalidateOnMount: true
  };

  if (initialAsapFeaturedProducts) {
    options.initialData = { initialAsapFeaturedProducts };
  }

  const customHeaders = useMemo(() => {
    // useMemo used to prevent customHeaders from changing
    // and thereby preventing useSwr from revalidating
    return { zc: postcode };
  }, [postcode]);

  const { data, isValidating, error, mutate } = useSWR(
    isFeaturedProductApplicable && (shouldFetchAsap || shouldFetchProduct)
      ? [`/featured/products/${ASAP}`, false, true, customHeaders]
      : null,
    fetcher,
    options
  );

  useEffect(() => {
    if (isFeaturedProductApplicable) {
      getUpdatedZone(() => {
        mutate();
      });
    }

    return () => {
      if (isFeaturedProductApplicable) {
        removeListnerUpdatedZone();
      }
    };
  }, [getUpdatedZone, removeListnerUpdatedZone, mutate]);

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      Array.isArray(modifiedData) && modifiedData?.forEach(product => {
        if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
          product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
        }
      });
    }
  }, [favoriteStatusChangedForProduct]);

  let modifiedData = null;
  if (!data) {
    modifiedData = null;
  } else if (data?.data) {
    modifiedData = data.data;
  } else {
    modifiedData = data?.initialAsapFeaturedProducts || [];
  }

  return {
    data: modifiedData,
    isLoading: isFeaturedProductApplicable && !error && !data,
    isError: isFeaturedProductApplicable && error,
    isValidating: isFeaturedProductApplicable && isValidating
  };
};

export const useScheduledFeaturedShop = props => {
  const { initialScheduledFeaturedProducts } = props;

  // const {
  //   state: {
  //     user: {
  //       shouldFetchSchedule,
  //       shouldFetchProduct,
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //       // favoriteStatusChangedForProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    shouldFetchSchedule,
    shouldFetchProduct,
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct);

  const { getUpdatedZone, removeListnerUpdatedZone } = useSocket();

  const options = {
    revalidateOnMount: true
  };

  if (initialScheduledFeaturedProducts) {
    options.initialData = { initialScheduledFeaturedProducts };
  }

  const customHeaders = useMemo(() => {
    // useMemo used to prevent customHeaders from changing
    // and thereby preventing useSwr from revalidating
    return { zc: postcode };
  }, [postcode]);

  const { data, isValidating, error, mutate } = useSWR(
    isFeaturedProductApplicable && (shouldFetchSchedule || shouldFetchProduct)
      ? [`/featured/products/${SCHEDULE}`, false, true, customHeaders]
      : null,
    fetcher,
    options
  );

  useEffect(() => {
    if (isFeaturedProductApplicable) {
      getUpdatedZone(() => {
        mutate();
      });
    }

    return () => {
      if (isFeaturedProductApplicable) {
        removeListnerUpdatedZone();
      }
    };
  }, [getUpdatedZone, removeListnerUpdatedZone, mutate]);

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      Array.isArray(modifiedData) && modifiedData?.forEach(product => {
        if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
          product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
        }
      });
    }
  }, [favoriteStatusChangedForProduct]);

  let modifiedData = null;
  if (!data) {
    modifiedData = null;
  } else if (data?.data) {
    modifiedData = data.data;
  } else {
    modifiedData = data?.initialScheduledFeaturedProducts || [];
  }

  return {
    data: modifiedData,
    isLoading: isFeaturedProductApplicable && !error && !data,
    isError: isFeaturedProductApplicable && error,
    isValidating: isFeaturedProductApplicable && isValidating
  };
};

export const useRecentViewedProducts = ({ initialRecentViewedProducts } = {}) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //     }
  //   }
  // } = useContext(appContext);

  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  } = useSelector(state => state.user)

  const options = { revalidateOnMount: true };
  let productsId = cookie.get(COOKIE_VARIABLES.recentViewedProductsId)
    ? JSON.parse(cookie.get(COOKIE_VARIABLES.recentViewedProductsId))
    : null;

  if (initialRecentViewedProducts) {
    options.initialData = { initialRecentViewedProducts, dontModify: true };
  }

  const { data, error, mutate } = useSWR(
    productsId ? `/products/product_details/${postcode}?product_details_ids=[${productsId}]` : null,
    fetcher,
    options
  );

  const recentViewedProducts = data && data.dontModify ? initialRecentViewedProducts : data?.products;

  return {
    data: recentViewedProducts,
    isLoading: !error && !data,
    mutate
  };
};
