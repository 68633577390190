import React, { useContext, useState } from 'react';

import appConfig from '../appConfig';
import { countryCodeOption } from '../constants/countryConst';
import { GRASSDOOR } from '../constants/website';
import useLogin from '../hooks/useLogin';
import VIA from '../constants/via';
import InlineLoader from './InlineLoader';
import alwaysTwoDigit from '../helpers/alwaysTwoDigit';
import appContext from '../Context/appContext';
import { useDispatch, useSelector } from 'react-redux';
import { setSmsCheckBox } from '../redux/slices/userSlice';
import OtpInputBox from './OTPInputBox/OTPInputBox';
import { setCustomerInfo } from '../redux/slices/cartSlice';
import storageService from '../services/storageService';

export default function LoginComponent(props) {
  const { redirectTo, closeDrawer, closeLoginPopup, confirmReward, forReward } = props;
  // const {
  //   // state: { user: { smsCheckbox } = {} } = {}, 
  //   dispatch
  // } = useContext(appContext);
  const smsCheckbox = useSelector(state => state.user.smsCheckbox)
  const dispatchRedux = useDispatch()
  const {
    formData: {
      country,
      phone,
      phoneError,
      otp,
      otpError,
      smsMessage,
      apiError,
      confirmingOTP,
      generatingOTP,
      switchBtn,
      displayOtpField
    },
    remainingTime,
    setPhone,
    setOTP,
    setCountry,
    requestOTP
  } = useLogin({
    closeDrawer,
    redirectTo,
    closeLoginPopup,
    confirmReward
  });
  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const toggleSMSdisclaimer = () => {
    // dispatch({ type: 'setSmsCheckBox', payload: { smsCheckbox: !smsCheckbox } });
    dispatchRedux(setSmsCheckBox({ smsCheckbox: !smsCheckbox }))
  };
  const [emailLocal, setEmail] = useState('')
  const [consent, setConsent] = useState(false)

  const handleRequestOtp = otpMethod => () => {
    requestOTP(otpMethod);
  };

  if (forReward) {
    return <div>
      <div class="max-w-sm mx-auto">
        <div className='mb-4'>
          <label className='text-[#999999]'>Email*</label>
          <input
            value={emailLocal}
            onChange={e => setEmail(e.target.value)}
            placeholder='abc@gmail.com'
            type='email'
            className='border w-full rounded px-2 py-2 text-[18px] text-[#616161] border border-[#C9CFD5]' />
        </div>
        <div className="form-group text-left mb-5 ">
          <label className='text-[#999999]'>Phone*</label>

          <div className="input-box-icon flex items-center gap-2 border border-[#C9CFD5] rounded overflow-hidden py-0 ">
            <select data-testid="#country_code" className=" max-w-[70px] border-r border-[#C9CFD5] text-[#999999] font-[600] py-[2px]" value={country} onChange={setCountry}>
              {countryCodeOption.map(item => (
                <option key={item.code} value={item.code}>
                  {`${item.name} +${item.code}`}
                </option>
              ))}
            </select>

            <input
              autoFocus
              data-testid="#phone_number"
              className="py-2 text-[18px] text-[#616161] pl-0 w-full focus:border-0 focus:outline-none"
              type="tel"
              placeholder="Mobile"
              maxLength="10"
              value={phone}
              onChange={setPhone}
              autoComplete="one-time-code"
            />
          </div>
        </div>
        <div className='flex items-start gap-2 mb-4'>
          <input value={consent} onChange={e => setConsent(e.target.checked)} type='checkbox' className='mt-1 flex-none h-5 w-5' />
          <p>By checking this box, you agree to receive marketing communications from {appConfig.APP_NAME} via automated SMS technology. SMS consent is not required to purchase. Frequency of communications may vary. Message and data rates may apply.</p>

        </div>
        {
          displayOtpField ? <div className='mb-5'>
            <label className='text-[#999999]'>OTP</label>

            <OtpInputBox length={4} onChange={setOTP} />
          </div>
            :
            null
        }
        <div className='flex justify-center'>
          <button
            disabled={!isValidEmail(emailLocal) || phone.length < 10 || !consent}
            onClick={
              () => {
                dispatchRedux(setCustomerInfo({ email: emailLocal }))
                storageService.setItemSession("localEmail", emailLocal)
                handleRequestOtp(VIA.Primary.value)();
              }
            }
            type='submit' className='text-[16px] py-2 px-16 rounded mx-auto bg-[var(--primary)] text-white disabled:opacity-[0.5]'>
            {
              confirmingOTP ?
                'Verifying code...'
                :
                remainingTime > 0 ?
                  `Resend Verification Code (00:${alwaysTwoDigit(remainingTime)})`
                  :
                  generatingOTP ? 'Generating OTP...' : 'Generate OTP'}
          </button>

        </div>
      </div>

    </div>
  }

  return (
    <>
      <div className="form-group text-left">
        <label className="mini-label">Phone</label>

        <div className="input-box-icon">
          <select data-testid="#country_code" className="form-control country-code" value={country} onChange={setCountry}>
            {countryCodeOption.map(item => (
              <option key={item.code} value={item.code}>
                {`${item.name} +${item.code}`}
              </option>
            ))}
          </select>

          <input
            autoFocus
            data-testid="#phone_number"
            className="form-control phone-number br-0"
            type="tel"
            placeholder="Mobile"
            maxLength="10"
            value={phone}
            onChange={setPhone}
            autoComplete="one-time-code"
          />

          {generatingOTP ? <InlineLoader text="Generating code" /> : null}
        </div>

        {phoneError ? <div className="error-msg">{phoneError}</div> : null}
      </div>

      {displayOtpField ? (
        <div className="form-group text-left">
          <label className="mini-label">Verification Code</label>

          <div className="relative">
            <input
              value={otp}
              data-testid="#verification_code"
              className="form-control otp-input br-0"
              type="tel"
              placeholder="Enter Verification Code"
              maxLength="4"
              onChange={setOTP}
            />

            {confirmingOTP ? <InlineLoader text="Verifying code" /> : null}
          </div>

          {otpError ? <div className="error-msg">{otpError}</div> : null}
        </div>
      ) : null}

      {apiError ? <div className="error-msg">{apiError}</div> : null}

      <div className="otp-on-call text-center">
        {/* Primary OTP button */}
        <button
          className=" bold one-rem-mb btn btn-primary has-box-shadow rouded-button"
          onClick={switchBtn ? handleRequestOtp(VIA.Secondary.value) : handleRequestOtp(VIA.Primary.value)}
          disabled={generatingOTP || remainingTime > 0}
          type="button"
          data-testid="#get_verification"
          data-cy={switchBtn ? VIA.Secondary.dataCy : VIA.Primary.dataCy}
        >
          {remainingTime > 0 ? (
            `Resend Verification Code (00:${alwaysTwoDigit(remainingTime)})`
          ) : (
            <>
              {switchBtn ? (
                <span>
                  Resend Verification Code via
                  <span className="blink-me">{` ${VIA.Secondary.text}`}</span>
                </span>
              ) : (
                `Get Verification Code via ${VIA.Primary.text}`
              )}
            </>
          )}
        </button>

        <div className="line-behind-text">
          <span>Or</span>
        </div>

        {/* Secondary OTP button */}
        <button
          className="btn btn-sm btn-link half-rem-mt underline"
          onClick={switchBtn ? handleRequestOtp(VIA.Primary.value) : handleRequestOtp(VIA.Secondary.value)}
          disabled={generatingOTP || remainingTime > 0}
          type="button"
          data-cy={switchBtn ? VIA.Primary.dataCy : VIA.Secondary.dataCy}
        >
          {remainingTime > 0 ? (
            `Resend Verification Code (00:${alwaysTwoDigit(remainingTime)})`
          ) : (
            <>
              {switchBtn ? `Resend Verification Code via ${VIA.Primary.text}` : `Get Verification Code via ${VIA.Secondary.text}`}
            </>
          )}
        </button>
      </div>

      {smsMessage && (
        <div className="custom-checkbox one-rem-mt two-rem-mb">
          <input type="checkbox" id="smsDisclaimerCheckBox" checked={smsCheckbox} onClick={toggleSMSdisclaimer} />

          <label className="align-top m-0 text-left" htmlFor="smsDisclaimerCheckBox">
            {process.env.NEXT_PUBLIC_APP === GRASSDOOR ? (
              <span className="inner small-text ">
                By checking this box, you agree to receive marketing communications from Grassdoor via automated SMS technology.
                SMS consent is not required to purchase. Frequency of communications may vary. Message and data rates may apply.
              </span>
            ) : (
              <span className="inner small-text ">
                {`By checking this box, you agree to receive marketing communications from ${appConfig.APP_NAME} and ${appConfig.APP_NAME}'s delivery partner, Grassdoor (including Grassdoor's partner sites) via automated SMS technology. SMS consent is not required to purchase. Frequency of communications may vary. Message and data rates may apply.`}
              </span>
            )}
          </label>
        </div>
      )}
    </>
  );
}
