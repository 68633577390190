import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { SCHEDULE_TYPE_ASAP, SCHEDULE_TYPE_FUTURE } from '../constants/scheduleType';
import { THEMEONE, THEMETWO, THEMETHREE } from '../constants/welcomePopupTypes';
import notificationTypes from '../constants/modalNotificationConst';
import CustomOunceTypes from '../constants/customOunceConsts';
import * as ScheduleType from '../constants/scheduleWindow';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import { DEFAULT_LOCATION, DEFAULT_ZIPCODE } from '../constants/default';
import SocketEmitConst from '../constants/socketConst';
import { GRASSDOOR } from '../constants/website';

import { useDeliveryDetails, useZipCodeStatus, useFreightLimit } from '../hooks/app';
import { usePurchaseLimit, useUpsellProducts } from '../hooks/cart';
import { useIdProof, useWeedyStatus } from '../hooks/User';
import { useBannerImages } from '../hooks/Shop';
import useSegment from '../hooks/segment';
import useScroll from '../hooks/scroll';
import useBraze from '../hooks/appboy';

import { getlocation, getAddress } from '../helpers/addressHelper';
import getRouterPath from '../helpers/getRouterPath';
import { getSocket } from '../helpers/SocketHelper';
import getCartType from '../helpers/getCartType';

import storageService from '../services/storageService';

import useSocket from '../NetworkCall/Socket';
import useAPI from '../NetworkCall/API';

import appContext from '../Context/appContext';

import appConfig from '../appConfig';
import { setRollbarWarning } from '../helpers/rollBar';
import { useDispatch, useSelector } from 'react-redux';
import { popNotificationAll, pushNotification, pushOnTopIfNotPresent } from '../redux/slices/modalSlice';
import { previousPath, saveLocation, setHistoryLength, setSmsCheckBox, setTCPARewardAmount, setTCPAShowMessage, setUserInfo, updateLocation } from '../redux/slices/userSlice';
import { isRewardFeatureApplicable } from '../constants/feature';
import { setCustomerInfo } from '../redux/slices/cartSlice';

const isCheckoutOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';

const AppEffect = ({
  initialLocationAddress = false,
  initialFreightLimit = false,
  initialPurchaseLimit = false,
  initialMarketingDetails = false,
  initialBannerData = false,
  fullBannerData = false,
  initialUpsellProducts = false,
  initialDeliveryAddressDetails = false,
  domainName
}) => {
  // const {
  //   state: {
  //     // user: {
  //     //   isLoggedIn,
  //     //   deliveryDetails: { postcode, latitude, longitude, deliveryTypeSelected, zoneId: existing_zone_id },
  //     //   deliveryDetails,
  //     //   couponData,
  //     //   tcpaRewardAmount,
  //     //   url,
  //     //   userInfo,
  //     //   userInfo: { isGiftCardRedeemState }
  //     // },
  //     // cart: { updateProductWidget },
  //     onLandingPage
  //   }
  // } = useContext(appContext);
  const onLandingPage = useSelector(state => state.other.onLandingPage)

  const updateProductWidget = useSelector(state => state.cart?.updateProductWidget)
  const {
    isLoggedIn,
    deliveryDetails: { postcode, latitude, longitude, deliveryTypeSelected, zoneId: existing_zone_id },
    deliveryDetails,
    couponData,
    tcpaRewardAmount,
    url,
    userInfo,
    userInfo: { isGiftCardRedeemState }
  } = useSelector(state => state.user);

  const notifications = useSelector(state => state.modal.notifications)
  const notificationsOverlap = useSelector(state => state.modal.notificationsOverlap)
  const dispatchRedux = useDispatch();


  const [thirdPartyMedicalPrevStatus, setThirdPartyMedicalPrevStatus] = useState();

  const prevDeliveryType = useRef(deliveryTypeSelected);
  const prevPostCode = useRef(postcode);

  const { handleUserOut, checkScrollHeight } = useScroll();

  const totalNotificationLength = notifications.length + notificationsOverlap.length;

  const {
    data: {
      tcpa_reward_amount: idProofTcpaAmount,
      sms_disclaimer_message: smsMessage,
      sms_disclaimer_checkbox: smsDisclaimerCheckbox
    },
    mutate: mutatePrescription
  } = useIdProof();

  const {
    data: {
      asapEnabled = true,
      hasGeofenceInventory = false,
      scheduleEnabled,
      locationId,
      priorityDeliveryConfigId,
      unified_menu: isUnifiedMenu,
      happyHours,
      asapReOpensAt,
      asapStartTime
    },
    mutate: mutateDeliveryDetails
  } = useDeliveryDetails(initialDeliveryAddressDetails);

  // const {
  //   data: { zoneId }
  // } = useZipCodeStatus(initialLocationAddress);

  const { is_enable: is_happy_hours_enable, code: couponCodeHaappyHours } = happyHours || {};

  const { mutate: mutateBannerImages } = useBannerImages({ initialBannerData });

  // const { mutate: mutateDeliveryDetails } = useDeliveryDetails(initialDeliveryAddressDetails);

  const { mutate: mutateFreightLimit } = useFreightLimit(initialFreightLimit);

  const { mutate: mutateUpsellProducts } = useUpsellProducts(
    initialLocationAddress,
    initialUpsellProducts,
    initialDeliveryAddressDetails
  );

  // const { data: purchaseLimit = {}, mutate: mutatePurchaseLimit } = usePurchaseLimit(initialPurchaseLimit);

  const {
    data: { status: thirdPartyMedicalStatus }
  } = useWeedyStatus();

  const router = useRouter();

  const { initBraze } = useBraze(router.pathname);

  const { initSegment, trackEvent } = useSegment();

  const { requestCustomerStatus } = useSocket();

  const { joinRoom } = useSocket();

  const {
    checkZipCodeStatus,
    getMarketingDetails,
    validateCoupon,
    syncLocalCartData,
    applyCoupon,
    setReferralLink,
    fetchSiteData,
    applyExistingGiftCards,
    logOut,
    getCart,
    authenticateProfile,
    addToSaveForLater,
    addToFavourites
    // fetchNewAsapDeliveryDetails
  } = useAPI(
    initialLocationAddress,
    initialFreightLimit,
    initialPurchaseLimit,
    initialUpsellProducts,
    initialDeliveryAddressDetails
  );

  const {
    query: { giftcard, slug }
  } = router;

  const storageUserInfo = storageService.getUserData() || {};

  const handleLogOut = async () => {
    if (isLoggedIn && !storageUserInfo.phone) {
      try {
        const authenticate = await authenticateProfile();
        if (authenticate) {
          return null;
        }
        await logOut();
        storageService.clearUserSpecificData();
        window.location.href = `/`;
      } catch (error) {
        console.error('AppEffect.jsx_handleLogOut_catch', error);
      }
    }
  };

  // const detectMouseOut = () => {
  //   document.addEventListener('mousemove', handleUserOut);
  // };

  // const handleScroll = useCallback(() => {
  //   screen.width < 786 && !storageService.getItemSession('exitIntent') && checkScrollHeight();
  // }, []);

  // useEffect(() => {
  //   if (locationId && postcode && router.pathname !== '/checkout') {
  //     fetchNewAsapDeliveryDetails({ location_id: locationId });
  //   }
  // }, [locationId, postcode]);

  // useEffect(() => {
  //   if (zoneId !== existing_zone_id) {
  //     dispatch({ type: 'saveLocation', payload: { ...deliveryDetails, zoneId: zoneId } });
  //   }
  // }, [zoneId]);

  const testOtherDays = date => {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].some(day => date.includes(day));
  };
  useEffect(() => {
    dispatchRedux(setHistoryLength(window?.history?.length))
    let email = JSON.parse(storageService.getItemSession("localEmail"))

    email && dispatchRedux(setCustomerInfo({ email }))
  }, [])
  useEffect(() => {
    const currentTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hourCycle: 'h23' });
    if (asapEnabled && asapReOpensAt && asapStartTime) {
      const currentTimeArray = currentTime.split(':');
      const asapTimeArray = asapStartTime.split(':');
      if (currentTimeArray.length === 3 && asapTimeArray.length === 3) {
        const diff = parseInt(24 - currentTimeArray[0]) + parseInt(asapTimeArray[0]);

        if (
          testOtherDays(asapReOpensAt) ||
          (asapReOpensAt.toLowerCase().includes('tomorrow') &&
            (diff > 24 || (diff === 24 && parseInt(asapTimeArray[1]) >= parseInt(currentTimeArray[1]))))
        ) {
          const href = `${window?.location.pathname}?tab=${SCHEDULE}`;
          router.replace(href, undefined, { shallow: true });
        }
      }
    }
  }, [asapEnabled, asapReOpensAt, asapStartTime]);

  // useEffect(() => {
  //   !storageService.getItemSession('exitIntent') &&
  //     screen.width > 786 &&
  //     document.addEventListener('readystatechange', event => {
  //       if (event.target.readyState === 'complete') {
  //         const test = setTimeout(() => {
  //           detectMouseOut();
  //           clearTimeout(test);
  //         }, 4000);
  //         document.removeEventListener('readystatechange', () => {});
  //       }
  //     });
  // }, []);

  // useEffect(() => {
  //   document.addEventListener('scroll', handleScroll);
  //   return () => {
  //     document.removeEventListener('scroll', handleScroll);
  //   };
  // }, [handleScroll]);

  useEffect(() => {
    handleLogOut();
  }, [isLoggedIn, storageUserInfo?.phone]);

  useEffect(() => {
    // update limit when third party medical status is ready
    if (thirdPartyMedicalPrevStatus !== 'ready') {
      if (thirdPartyMedicalStatus === 'ready' && thirdPartyMedicalStatus !== thirdPartyMedicalPrevStatus) {
        getCart();
        // mutatePurchaseLimit();
        mutatePrescription();
      }
      setThirdPartyMedicalPrevStatus(thirdPartyMedicalStatus);
    }
  }, [thirdPartyMedicalStatus]);

  useEffect(() => {
    // dispatch({ type: 'setSmsCheckBox', payload: { smsCheckbox: smsDisclaimerCheckbox } });
    dispatchRedux(setSmsCheckBox({ smsCheckbox: smsDisclaimerCheckbox }))
  }, [smsDisclaimerCheckbox]);

  useEffect(() => {
    if (!asapEnabled) {
      // dispatch({ type: 'saveLocation', payload: { ...deliveryDetails, deliveryTypeSelected: SCHEDULE_TYPE_FUTURE } });
      dispatchRedux(saveLocation({ ...deliveryDetails, deliveryTypeSelected: SCHEDULE_TYPE_FUTURE }))
    }
  }, [asapEnabled]);

  useEffect(() => {
    if (!scheduleEnabled) {
      // dispatch({ type: 'saveLocation', payload: { ...deliveryDetails, deliveryTypeSelected: SCHEDULE_TYPE_ASAP } });
      dispatchRedux(saveLocation({ ...deliveryDetails, deliveryTypeSelected: SCHEDULE_TYPE_ASAP }))
    }
  }, [scheduleEnabled]);

  useEffect(() => {
    if (hasGeofenceInventory) {
      // dispatch({ type: 'saveLocation', payload: { ...deliveryDetails, deliveryTypeSelected: SCHEDULE_TYPE_ASAP } });
      dispatchRedux(saveLocation({ ...deliveryDetails, deliveryTypeSelected: SCHEDULE_TYPE_ASAP }))
    }
  }, [hasGeofenceInventory]);

  useEffect(async () => {
    const bannerSeenOnce = storageService.getHappyHoursBanner() || null;
    if (router.pathname === '/') {
      if (is_happy_hours_enable && !bannerSeenOnce) {
        trackEvent('show_happy_hours_banner', {
          active: true
        }, true);
        storageService.setHappyHoursBanner(true);
      }
    }
  }, [JSON.stringify(happyHours)]);

  useEffect(() => {
    // dispatch({ type: 'saveLocation', payload: { ...deliveryDetails, locationId } });
    dispatchRedux(saveLocation({ ...deliveryDetails, locationId }))
  }, [locationId]);

  useEffect(() => {
    process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true' &&
      !storageService.getItemSessionParsed('domainName') &&
      domainName &&
      storageService.setItemSession('domainName', domainName);
  }, [domainName]);

  useEffect(() => {
    if (!isCheckoutOnly && asapEnabled) {
      // dispatch({
      //   type: 'saveLocation',
      //   payload: {
      //     ...deliveryDetails,
      //     deliveryTypeSelected: priorityDeliveryConfigId === 0 ? SCHEDULE_TYPE_ASAP : deliveryTypeSelected
      //   }
      // });
      dispatchRedux(saveLocation({
        ...deliveryDetails,
        deliveryTypeSelected: priorityDeliveryConfigId === 0 ? SCHEDULE_TYPE_ASAP : deliveryTypeSelected
      }))
    }
  }, [priorityDeliveryConfigId, asapEnabled]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (
      totalNotificationLength &&
      ((notifications.length && notifications[0].type == 'deliverAddressPopup') ||
        (notificationsOverlap.length && notificationsOverlap[0].type == 'deliverAddressPopup'))
    ) {
      body.classList.remove('modal-open');
    } else if (totalNotificationLength) {
      body.classList.add('modal-open');
    } else {
      body.classList.remove('modal-open');
    }
  }, [totalNotificationLength]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (notifications.length || notificationsOverlap.length) {
      const { type } = notifications.length ? notifications[0] : notificationsOverlap[0];
      const { searchModal, categoryVolumePricing, bulkQuantityPopup, productDetailsPopup, productDetails, bccPopup } =
        notificationTypes;
      if (
        type == searchModal ||
        type == categoryVolumePricing ||
        type == bulkQuantityPopup ||
        type == productDetailsPopup ||
        type == productDetails ||
        type == bccPopup
      ) {
        body.classList.add('hide-acsb-icon');
      }
    } else {
      body.classList.remove('hide-acsb-icon');
    }
  }, [totalNotificationLength]);

  useEffect(() => {
    const preventPopNotificationFromPages = ['/locationselect', '/customounce', '/customounce/[...slug]'];
    if (!preventPopNotificationFromPages.includes(url)) {
      dispatchRedux(popNotificationAll())
    }
    // dispatch({ type: 'previousPath', payload: { url: router.pathname } });
    dispatchRedux(previousPath({ url: router.pathname }))
  }, [router.pathname]);

  useEffect(async () => {
    function checkAgeConfirmation() {
      if (!storageService.getAgeConfirmationShowPopup(true) && !isLoggedIn && !isCheckoutOnly) {
        dispatchRedux(pushNotification({ type: notificationTypes.ageConfirm, data: { domainName } }))
      } else if (
        !storageService.getUpdatedAgeConfirmationShowPopup() &&
        isLoggedIn &&
        !['dosist', 'Grassdoor'].includes(appConfig.APP_NAME)
      ) {
        dispatchRedux(pushNotification({ type: notificationTypes.updatedAgeConfirm, data: { domainName } }))
      }
    }

    async function couponAction() {
      const { token: couponToken } = couponData;
      if (couponToken) {
        await setReferralLink(couponToken);
      } else if (couponData && couponData.discount_code) {
        /**
         * -------------
         * APPLY COUPON
         * -------------
         * Call Apply Coupon to check if there is any discount for the loggedin customer
         * 1. Pass the coupon if present in localstorage (localstorage was already sync with store)
         * 2. Call with 0 otherwise
         */
        // Case 1
        await applyCoupon(couponData.discount_code);
      } else {
        // Case 2
        await applyCoupon(0);
      }
    }

    async function syncLocalWishListData() {
      const saveForLaterData = storageService.getSaveForLaterItems({ includingDisabled: true });
      addToSaveForLater({
        items: saveForLaterData,
        hideSuccess: true,
        hideError: true,
        callSegmentEvent: false,
        updateIsSavedForLaterItemAddedOrRemoved: false
      });
      storageService.deleteSaveForLaterItems();
      // do same for favourites
      const favouritesData = storageService.getFavourites({ includingDisabled: true });
      await addToFavourites({
        items: favouritesData,
        handleLoadingState: true,
        hideSuccess: true,
        hideError: true,
        callSegmentEvent: false,
        updateIsFavouriteItemAddedOrRemoved: false
      });
      storageService.deleteFavouriteItems();
    }

    if (isLoggedIn) {
      const localCartItems = storageService.getCart();
      const cartType = getCartType(localCartItems);
      let scheduleType = null;

      if (localCartItems?.length > 0) {
        const normalProducts = [];
        const ounceProducts = [];

        localCartItems.forEach(item => {
          if (item.ounce_id) {
            scheduleType = item.asap ? ScheduleType.ASAP : ScheduleType.Scheduled;

            const ounceObj = {
              ounce_type:
                item.ounce_product_count === CustomOunceTypes.HALF.count
                  ? CustomOunceTypes.HALF.type
                  : CustomOunceTypes.FULL.type,
              schedule_type: scheduleType,
              products: item.ounce_products.map(product => ({
                product_id: product.product_id,
                quantity: product.quantity,
                schedule_type: scheduleType,
                list: scheduleType === ScheduleType.ASAP ? 'ASAP List' : 'Schedule List'
              }))
            };

            ounceProducts.push(ounceObj);
          } else {
            const currentItem = {
              product_id: item.product_id,
              is_bundle: 0,
              quantity: item.quantity,
              list: item.list
            };
            if (item.bundle_id) {
              currentItem.product_id = item.bundle_id;
              currentItem.is_bundle = 1;
            }

            if (item.is_offer_allowed || item.is_offer_allowed === 0) {
              currentItem.is_offer_allowed = item.is_offer_allowed;
              currentItem.buy_product_with_campaigns_id = item?.offer_product?.[0]?.buy_products_id;
            }

            if (item.is_avail || item.is_avail === 0) {
              currentItem.is_avail = item.is_avail;
            }

            normalProducts.push(currentItem);
          }
        });

        if (process.env.NEXT_PUBLIC_APP !== GRASSDOOR && isUnifiedMenu === 1) {
          if (cartType == ASAP) {
            scheduleType = SCHEDULE_TYPE_ASAP;
          } else if (cartType == SCHEDULE) {
            scheduleType = SCHEDULE_TYPE_FUTURE;
          }
        }

        syncLocalCartData(normalProducts, ounceProducts, scheduleType);
      }

      couponAction();
      await syncLocalWishListData();
    }

    if (isGiftCardRedeemState) {
      // dispatch({ type: 'setUserInfo', payload: { ...userInfo, isGiftCardRedeemState: false } });
      dispatchRedux(setUserInfo({ ...userInfo, isGiftCardRedeemState: false }))
    }

    if (!router.pathname.includes('webview-connect')) {
      checkAgeConfirmation();
    }

    mutateDeliveryDetails();
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      if (!isGiftCardRedeemState && !router.pathname.includes('/login')) {
        applyExistingGiftCards();
      }
    }
  }, [isLoggedIn && router.pathname]);

  useEffect(() => {
    const socket = getSocket();
    socket.once(SocketEmitConst.CONNECT, () => {
      requestCustomerStatus();
      joinRoom();
    });

    socket.on(SocketEmitConst.RECONNECT, () => {
      requestCustomerStatus();
      joinRoom();
    });

    socket.on(SocketEmitConst.ORDER_COMPLETED, () => {
      requestCustomerStatus();
    });

    socket.on(SocketEmitConst.CUSTOMER_SIGNATURE, () => {
      requestCustomerStatus();
    });

    socket.on(SocketEmitConst.ENTER_PAC, () => {
      requestCustomerStatus();
    });

    if (router.pathname === '/' && storageService.getAgeConfirmationShowPopup()) {
      // Braze custom event fired if app boy loaded in the window
      if (isLoggedIn) {
        trackEvent('landing-page-accepted-terms-logged-in');
      } else {
        trackEvent('landing-page-accepted-terms-logged-out');
      }
    }

    if (!isLoggedIn && couponData.discount_code) {
      validateCoupon(couponData.discount_code);
    }

    initSegment({ domainName });
    initBraze();

    async function revalidateOnMount() {
      await mutateDeliveryDetails();
      mutateFreightLimit();
      !updateProductWidget ? mutateUpsellProducts() : null;
      fetchSiteData();
    }
    revalidateOnMount();
  }, []);

  useEffect(() => {
    // Done to fix wrong zipcode issue in local storage
    // Temp code, to be deleted by 22July 2023
    async function zipcodeCorrection() {
      const {
        latitude: currentSavedLatitude,
        longitude: currentSavedLongitude,
        postcode: currentSavedPostcode
      } = deliveryDetails || {};

      const localStorageDeliveryDetails = storageService.getDeliveryDetails();

      const isZipcodeCorrected = storageService.getIsZipcodeCorrected();

      if (currentSavedPostcode && `${currentSavedPostcode}`?.length === 3) {
        trackEvent('zipcode3Digit', { localStorageDeliveryDetails, contextDeliveryDetails: deliveryDetails });

        if (
          !isZipcodeCorrected &&
          currentSavedLatitude &&
          currentSavedLongitude &&
          currentSavedLatitude !== DEFAULT_LOCATION.lat &&
          currentSavedLongitude !== DEFAULT_LOCATION.lng &&
          currentSavedPostcode !== DEFAULT_ZIPCODE
        ) {
          try {
            const placeObj = await getAddress({
              lat: currentSavedLatitude,
              lng: currentSavedLongitude
            });

            if (!placeObj) {
              dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup, data: { domainName } }))
            } else {
              // dispatch({
              //   type: 'saveLocation',
              //   payload: {
              //     ...deliveryDetails,
              //     address: placeObj.address,
              //     address_id: 0,
              //     city: placeObj.city,
              //     country: placeObj.country,
              //     latitude: placeObj.latitude,
              //     longitude: placeObj.longitude,
              //     postcode: placeObj.postcode,
              //     state: placeObj.state,
              //     streetNumber: placeObj.streetNumber,
              //     streetName: placeObj.streetName
              //   }
              // });
              dispatchRedux(saveLocation({
                ...deliveryDetails,
                address: placeObj.address,
                address_id: 0,
                city: placeObj.city,
                country: placeObj.country,
                latitude: placeObj.latitude,
                longitude: placeObj.longitude,
                postcode: placeObj.postcode,
                state: placeObj.state,
                streetNumber: placeObj.streetNumber,
                streetName: placeObj.streetName
              }))

              storageService.setIsZipcodeCorrected(true);
            }
          } catch (error) {
            console.log('zipcodeCorrectionError', error);
            const rollbarLog = { ...error, deliveryDetails, isZipcodeCorrected };
            setRollbarWarning('zipcodeCorrectionError', rollbarLog);
          }
        }
      }
    }

    zipcodeCorrection();
  }, [deliveryDetails, dispatchRedux, domainName, trackEvent]);

  const checkForRewards = async () => {
    const zipCode = await checkZipCodeStatus({ ...deliveryDetails, postcode })
    if (zipCode.validZipcode) {
      //call pop up here
      dispatchRedux(pushOnTopIfNotPresent({ type: notificationTypes.firstTimeUserReward }))

    }

  }

  useEffect(() => {
    isRewardFeatureApplicable && !storageService.getItemSession('firstTimeReward') && !isLoggedIn && checkForRewards()
  }, [postcode])

  useEffect(() => {
    async function marketingDetailActions() {
      const marketingDetails = await getMarketingDetails();
      const promoOffer = storageService.getPromoOfferConfirmationShowPopup();
      if (
        marketingDetails &&
        (marketingDetails.theme_id === THEMETWO || marketingDetails.theme_id === THEMETHREE) &&
        !isLoggedIn
      ) {
        if (promoOffer) {
          if (Number(marketingDetails.updatedTime) > Number(promoOffer.updatedTime)) {
            dispatchRedux(pushNotification({ type: notificationTypes.promoOffer, data: { marketingDetails, isMarketing: true } }))
          }
        } else {
          dispatchRedux(pushNotification({ type: notificationTypes.promoOffer, data: { marketingDetails, isMarketing: true } }))
        }
      } else if (marketingDetails && marketingDetails.theme_id === THEMEONE) {
        if (
          (marketingDetails && !promoOffer) ||
          (marketingDetails && promoOffer && marketingDetails.id !== promoOffer.id) ||
          (marketingDetails &&
            promoOffer &&
            marketingDetails.id === promoOffer.id &&
            Number(marketingDetails.updatedTime) > Number(promoOffer.updatedTime))
        ) {
          dispatchRedux(pushNotification({ type: notificationTypes.promoOffer, data: { marketingDetails, isMarketing: true } }))
        }
      }
    }

    async function validatePostcode() {
      if (!postcode || postcode === DEFAULT_ZIPCODE) {
        // Try to auto detact address
        try {
          const coordinates = await getlocation();

          if (!coordinates) {
            dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup, data: { domainName } }))

            marketingDetailActions(initialMarketingDetails);
            return;
          }

          const placeObj = await getAddress({
            lat: coordinates.latitude,
            lng: coordinates.longitude
          });

          if (!placeObj) {
            dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup, data: { domainName } }))

            marketingDetailActions(initialMarketingDetails);
            return;
          }
          const { validZipcode } = await checkZipCodeStatus({
            postcode: placeObj.postcode,
            latitude: placeObj.latitude,
            longitude: placeObj.longitude,
            initialLocationAddress
          });
          dispatchRedux(saveLocation({
            address: placeObj.address,
            address_id: 0,
            city: placeObj.city,
            country: placeObj.country,
            latitude: placeObj.latitude,
            longitude: placeObj.longitude,
            postcode: placeObj.postcode,
            state: placeObj.state,
            streetNumber: placeObj.streetNumber,
            streetName: placeObj.streetName
          }))

          if (!validZipcode) {
            dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup, data: { domainName } }))
          }

          marketingDetailActions(initialMarketingDetails);
        } catch (error) {
          const { validZipcode } = await checkZipCodeStatus({
            postcode: postcode || DEFAULT_ZIPCODE,
            latitude,
            longitude,
            initialLocationAddress
          });

          if (!prevPostCode.current && !validZipcode) {
            dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup, data: { domainName } }))
          }

          marketingDetailActions(initialMarketingDetails);
        }
      } else {
        const { validZipcode } = await checkZipCodeStatus({
          postcode,
          latitude,
          longitude,
          initialLocationAddress
        });

        if (!validZipcode) {
          dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup, data: { domainName } }))
        }

        marketingDetailActions(initialMarketingDetails);
      }
    }

    if (!isCheckoutOnly && !giftcard && router.route !== '/gift-cards-redeem' && router.route !== '/delivery' && !onLandingPage) {
      validatePostcode();
    }

    async function marketingDetailActions(initialMarketingDetails) {
      const marketingDetails = Object.keys(initialMarketingDetails).length ? initialMarketingDetails : (await getMarketingDetails());
      const promoOffer = storageService.getPromoOfferConfirmationShowPopup();

      if (
        marketingDetails &&
        (marketingDetails.theme_id === THEMETWO || marketingDetails.theme_id === THEMETHREE) &&
        !isLoggedIn
      ) {
        if (promoOffer) {
          if (Number(marketingDetails.updatedTime) > Number(promoOffer.updatedTime)) {
            dispatchRedux(pushNotification({ type: notificationTypes.promoOffer, data: { marketingDetails, isMarketing: true } }))
          }
        } else {
          dispatchRedux(pushNotification({ type: notificationTypes.promoOffer, data: { marketingDetails, isMarketing: true } }))
        }
      } else if (marketingDetails && marketingDetails.theme_id === THEMEONE) {
        if (
          (marketingDetails && !promoOffer) ||
          (marketingDetails && promoOffer && marketingDetails.id !== promoOffer.id) ||
          (marketingDetails &&
            promoOffer &&
            marketingDetails.id === promoOffer.id &&
            Number(marketingDetails.updatedTime) > Number(promoOffer.updatedTime))
        ) {
          dispatchRedux(pushNotification({ type: notificationTypes.promoOffer, data: { marketingDetails, isMarketing: true } }))
        }
      }
    }

    if (!isLoggedIn && couponData.discount_code) {
      validateCoupon(couponData.discount_code);
    }

    // initSegment();
    // initBraze();

    async function revalidateOnMount() {
      await mutateDeliveryDetails();
      mutateFreightLimit();
      !updateProductWidget ? mutateUpsellProducts() : null;
      fetchSiteData(fullBannerData);
    }

    revalidateOnMount();
  }, [onLandingPage]);

  useEffect(() => {
    if (postcode !== prevPostCode.current) {
      prevPostCode.current = postcode;
      mutateBannerImages();
      // mutatePurchaseLimit();
    }
  }, [postcode]);

  useEffect(() => {
    if (smsMessage === true || smsMessage === false) {
      // dispatch({ type: 'setTCPAShowMessage', payload: { smsMessage } });
      dispatchRedux(setTCPAShowMessage({ smsMessage }))
    }
  }, [smsMessage]);

  // useEffect(() => {
  //   const isPopupHidden = storageService.getDeliveryPopup();
  //   const deliveryPopupSession = storageService.getDeliveryPopupSession();

  //   if (
  //     router.pathname === '/' &&
  //     asapEnabled &&
  //     scheduleEnabled &&
  //     !deliveryPopupSession &&
  //     storageService.getAgeConfirmationShowPopup(true) &&
  //     !isPopupHidden &&
  //     modalClosed &&
  //     process.env.NEXT_PUBLIC_APP === GRASSDOOR
  //   ) {
  //     dispatch({ type: 'pushNotification', payload: { type: notificationTypes.redirectionPopup, data: { showCheckbox: true } } });
  //     storageService.setDeliveryPopupSession(true);
  //   }
  // }, [asapEnabled, scheduleEnabled, deliveryDetails]);

  useEffect(() => {
    if (deliveryTypeSelected !== prevDeliveryType.current) {
      prevDeliveryType.current = deliveryTypeSelected;

      if (!isLoggedIn) {
        if (couponData.discount_code) {
          validateCoupon(couponData.discount_code);
        }
      }
    }
  }, [deliveryTypeSelected]);

  useEffect(() => {
    if (idProofTcpaAmount !== undefined && idProofTcpaAmount !== tcpaRewardAmount) {
      // dispatch({ type: 'setTCPARewardAmount', payload: { tcpaRewardAmount: idProofTcpaAmount } });
      dispatchRedux(setTCPARewardAmount({ tcpaRewardAmount: idProofTcpaAmount }))
    }
  }, [idProofTcpaAmount]);

  return (
    <>
      <CartEffect
        initialLocationAddress={initialLocationAddress}
        initialFreightLimit={initialFreightLimit}
        initialPurchaseLimit={initialPurchaseLimit}
        initialUpsellProducts={initialUpsellProducts}
        initialDeliveryAddressDetails={initialDeliveryAddressDetails}
      />
    </>
  );
};

const CartEffect = ({
  initialLocationAddress,
  initialFreightLimit,
  initialPurchaseLimit,
  initialUpsellProducts,
  initialDeliveryAddressDetails
}) => {
  // const {
  //   state: {
  //     // user: {
  //     //   deliveryDetails: { postcode, deliveryDate, deliveryTime, latitude, longitude, deliveryTypeSelected, deliveryDateTime },
  //     //   isLoggedIn,
  //     //   couponData: { user_coupon: userCoupon, id: couponID },
  //     //   couponData
  //     // }
  //   },
  //   state,
  //   // dispatch
  // } = useContext(appContext);
  const {
    deliveryDetails: { postcode, deliveryDate, deliveryTime, latitude, longitude, deliveryTypeSelected, deliveryDateTime },
    isLoggedIn,
    couponData: { user_coupon: userCoupon, id: couponID },
    couponData
  } = useSelector(state => state.user)
  const userState = useSelector(state => state.user)
  const cartState = useSelector(state => state.cart)
  const dispatchRedux = useDispatch()
  const { paymentType, useWalletAmount, useGiftWalletAmount } = useSelector(state => state.cart?.customerData) || {}
  const { driverTip } = useSelector(state => state.cart.cartDetails)
  const { cart_items: cartItems = [] } = useSelector(state => state.cart.cartData) || {}
  const {
    data: { validZipcode }
  } = useZipCodeStatus(initialLocationAddress);

  const { checkProductAvailability, getCart, applyCoupon } = useAPI(
    initialLocationAddress,
    initialFreightLimit,
    initialPurchaseLimit,
    initialUpsellProducts,
    initialDeliveryAddressDetails
  );

  const {
    data: { deliveryDays, asapEnabled = true, happyHours },
    isLoading
  } = useDeliveryDetails(initialDeliveryAddressDetails);

  const { is_enable: is_happy_hours_enable, code: couponCodeHaappyHours, coupon_details: couponDetails } = happyHours || {};

  const happyHoursApplicableForDeliveryType = useMemo(() => {
    const HHApplyCouponApplicableFor = is_happy_hours_enable ? couponDetails?.delivery_type : false;
    return HHApplyCouponApplicableFor === 3 || HHApplyCouponApplicableFor === (deliveryTypeSelected % 10) + 2;
  }, [is_happy_hours_enable, deliveryTypeSelected, couponDetails]);

  const getCartKeys = {
    postcode,
    deliveryTime,
    deliveryDate,
    deliveryTypeSelected,
    latitude,
    longitude,
    isLoggedIn,
    userCoupon,
    couponID,
    paymentType,
    useWalletAmount,
    useGiftWalletAmount,
    driverTip,
    validZipcode,
    deliveryDateTime
  };
  const dataRef = useRef(getCartKeys);
  // const newState = useRef(state);
  const newdeliveryDays = useRef(deliveryDays);

  // useEffect(() => {
  //   newState.current = state;
  // }, [state]);

  useEffect(() => {
    newdeliveryDays.current = deliveryDays;
  }, [deliveryDays]);

  // useEffect(() => {
  //   dataRef.current = getCartKeys;
  // }, [getCartKeys]);

  useEffect(() => {
    async function updateData() {
      // const {
      //   user: { couponData: newCoupondata }
      // } = newState.current;
      const { couponData: newCoupondata } = userState
      // const newCoupondata = useSelector(state => state.user.couponData)
      if (isLoggedIn && cartItems.length) {
        await applyCoupon(newCoupondata?.discount_code || 0);
      } else if (!isLoading) {
        await getCart();
      }
    }

    function visibilitychange() {
      if (!document.hidden) {
        const localAddress = storageService.getDeliveryDetails();
        updateData();
        setTimeout(() => {
          if (localAddress) {
            if (localAddress.postcode !== dataRef.current.postcode) {
              // dispatch({ type: 'updateLocation', payload: localAddress });
              dispatchRedux(updateLocation(localAddress))
            }
          }
        }, 2000);
      }
    }

    updateData();
    document.addEventListener('visibilitychange', visibilitychange);

    return () => {
      document.removeEventListener('visibilitychange', visibilitychange);
    };
  }, [isLoading]);

  useEffect(() => {
    let same = true;
    const changedKeys = [];

    Object.keys(getCartKeys).forEach(key => {
      if (dataRef.current[key] !== getCartKeys[key]) {
        dataRef.current[key] = getCartKeys[key];
        changedKeys.push(key);
        same = false;
      }
    });

    if (same || !validZipcode) {
      return;
    }

    async function updateData() {
      const cartType = getCartType(cartItems);
      // const {
      //   user: { cartSwitched },
      //   cart: { cartData: { asap_count: asapCurrentCount = 0 } = {} } = {}
      // } = newState.current;
      const { cartSwitched } = userState
      const { cartData: { asap_count: asapCurrentCount = 0 } = {} } = cartState
      let type = deliveryTypeSelected;
      if (isLoggedIn && asapCurrentCount == 0 && cartItems.length) {
        if (cartType !== ASAP) {
          type = SCHEDULE_TYPE_FUTURE;
        }
      }
      if (!asapEnabled) {
        type = SCHEDULE_TYPE_FUTURE;
      }
      if (cartSwitched) {
        if (getRouterPath(window.location.pathname) == 'bundle') {
          type = SCHEDULE_TYPE_FUTURE;
        } else if (cartType == ASAP) {
          type = SCHEDULE_TYPE_ASAP;
        } else if (cartType == SCHEDULE) {
          type = SCHEDULE_TYPE_FUTURE;
        } else {
          type = deliveryTypeSelected;
        }
      } else if (cartType == ASAP) {
        type = SCHEDULE_TYPE_ASAP;
      } else if (cartType == SCHEDULE) {
        type = SCHEDULE_TYPE_FUTURE;
      }

      try {
        if (window.location.pathname === '/checkout') {
          if (
            changedKeys.includes('postcode') ||
            (!cartSwitched && changedKeys.includes('deliveryTypeSelected')) ||
            (changedKeys.includes('paymentType') && window.location.pathname === '/checkout' && !cartSwitched)
          ) {
            if (cartItems.length) {
              await checkProductAvailability({
                postcode,
                latitude,
                longitude,
                scheduleType: type,
                checkAvailability: true
              });
            }
          }
        }

        if (isLoggedIn) {
          if (
            couponData.discount_code &&
            couponData.discount_code !== '' &&
            !(is_happy_hours_enable && !happyHoursApplicableForDeliveryType && couponData.discount_code === couponCodeHaappyHours)
          ) {
            await applyCoupon(couponData.discount_code);
          } else {
            await getCart();
          }
        } else {
          await getCart();
        }
      } catch (error) {
        // Don't do anything
      }
    }
    updateData();
  }, [...Object.values(getCartKeys), getCartKeys.deliveryDateTime]);
  return null;
};

export default AppEffect;
