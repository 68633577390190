import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    asapProductCategory: [],
    scheduleProductCategory: []
};

// Helper function to compare arrays by id
const arraysEqualById = (arr1, arr2) => {
    const ids1 = arr1.map(item => item.id);
    const ids2 = arr2.map(item => item.id);
    return ids1.length === ids2.length && ids1.every((id, index) => id === ids2[index]);
};

// Helper function to modify the products by adding `name` and `link`
const modifyProductData = (products) => {
    return products.map(product => ({
        ...product,
        category: true,
        name: product.category_name, // Add new key `name`
        link: `/shop/${product.category_slug}` // Add new key `link`
    }));
};

const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        setProductList(state, action) {
            const { asap = [], schedule = [] } = action.payload;

            // Modify the products to include `name` and `link`
            const modifiedAsap = modifyProductData(asap);
            const modifiedSchedule = modifyProductData(schedule);

            // Compare by id to determine if an update is needed
            const isAsapEqual = arraysEqualById(state.asapProductCategory, modifiedAsap);
            const isScheduleEqual = arraysEqualById(state.scheduleProductCategory, modifiedSchedule);

            // Update state only if the products are different
            if (!isAsapEqual) {
                state.asapProductCategory = modifiedAsap;
            }

            if (!isScheduleEqual) {
                state.scheduleProductCategory = modifiedSchedule;
            }
        }
    }
});

export const { setProductList } = sidebarSlice.actions;
export default sidebarSlice.reducer;
