import get from 'lodash/get';
import axios from 'axios';
import { toast } from 'react-toastify';
import cookie from 'js-cookie';
import axiosService, { getErrorMessage, getSuccessMessage } from '../utils/ajax';
import storageService from './storageService';
import { DEFAULT_GIFTCARD_PLACE_ID, DEFAULT_GIFTCARD_ZIPCODE, DEFAULT_ZIPCODE } from '../constants/default';
import * as COOKIE_VARIABLES from '../constants/cookieVariables';

const currentExecutingRequests = {};
class InterceptorsService {
  constructor() {
    axiosService.interceptors.request.use(
      config => {
        const localConfig = config;
        if (!localConfig.external) {
          if (!localConfig.headers) {
            localConfig.headers = {};
          }
          // Pass Auth Token if present, and if no token is passed by API definition
          const token = cookie.get(COOKIE_VARIABLES.token);

          if (token && !localConfig?.headers?.Authorization && !localConfig.hideAuthToken) {
            localConfig.headers.Authorization = `Bearer ${token}`;
          }

          // Set default content-type
          if (!localConfig.headers['content-type']) {
            localConfig.headers['content-type'] = 'application/json';
          }

          // Pass zipcode to all request
          if (localConfig.warehouse !== 'warehouse') {
            const localAddress = storageService.getDeliveryDetails();
            if (!localConfig.headers.zc) {
              localConfig.headers.zc = localAddress?.postcode || DEFAULT_ZIPCODE;
            }
            if (!localConfig.headers.placeid && localAddress?.placeId) {
              localConfig.headers.placeid = localAddress.placeId;
            }
            if (!localConfig.headers.lat && localAddress?.latitude) {
              localConfig.headers.lat = localAddress.latitude;
            }
            if (!localConfig.headers.lng && localAddress?.longitude) {
              localConfig.headers.lng = localAddress.longitude;
            }
          }

          // giftcard default address
          if (localConfig.giftcard) {
            // console.log('localConfig.giftcard', localConfig.giftcard);
            localConfig.headers.zc = DEFAULT_GIFTCARD_ZIPCODE;
            localConfig.headers.placeid = DEFAULT_GIFTCARD_PLACE_ID;
          }
          if (process.browser && window.location.search.includes('giftcard')) {
            // console.log('localConfig.giftcard', localConfig.giftcard);
            localConfig.headers.zc = DEFAULT_GIFTCARD_ZIPCODE;
            localConfig.headers.placeid = DEFAULT_GIFTCARD_PLACE_ID;
          }

          if (currentExecutingRequests[config.cancelRequestUrl]) {
            const source = currentExecutingRequests[config.cancelRequestUrl];
            delete currentExecutingRequests[config.cancelRequestUrl];
            source.cancel();
          }
          if (config.requestUrl) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            localConfig.cancelToken = source.token;
            currentExecutingRequests[config.requestUrl] = source;
          }
        }
        return localConfig;
      },
      error => Promise.reject(error)
    );

    axiosService.interceptors.response.use(
      response => {
        if (process.browser) {
          /**
           * Common error message handeling for all API
           * This block handle displaying error if there is any error message inside APi response with success HTTP code
           */
          const errorMsg = getErrorMessage(get(response, 'data', {}));
          if (errorMsg) {
            toast.error(errorMsg, { toastId: `${errorMsg.replace(/\s+/g, '')}` });
          }

          /**
           * Common success message handeling for all API
           */
          const successMsg = getSuccessMessage(get(response, 'data', {}));
          const method = get(response, 'config.method');
          const hideSuccess = get(response, 'config.hideSuccess');
          if (successMsg && method !== 'get' && !hideSuccess) {
            toast.success(successMsg);
          }
        }
        return response;
      },
      error => {
        const response = get(error, 'response', {});
        if (process.browser && response && response.config && !response.config.external && response.status === 401) {
          if (response.config.url.includes('analytics') || (response.config.requestUrl == "/carts/tier_rewards" || response.config.requestUrl == "/carts/guest/tier_rewards")) {
          } else {
            storageService.clearUserSpecificData();
            // eslint-disable-next-line no-restricted-globals
            if (process.env.NEXT_PUBLIC_CHECKOUT_ONLY !== 'true') {
              if (window.location.pathname !== "/login") {

                window.location.href = `/login?redirect=${window.location.pathname}`;
              }
            }
          }
        } else if (process.browser && error && response.config && !response.config.hideError) {
          /**
           * Common error message handeling for all API
           * This block handle displaying error if found any error HTTP code
           */
          const errorMsg = getErrorMessage(response, true);
          if (errorMsg) {
            toast.error(errorMsg, { toastId: `${errorMsg.replace(/\s+/g, '')}` });
          } else {
            toast.error('Something went wrong!', { toastId: 'Somethingwentwrong' });
          }
        }
        return Promise.reject(response);
      }
    );
  }
}

export default new InterceptorsService();
