import React, { useReducer, useEffect, useCallback } from 'react';
import { SWRConfig } from 'swr';
import cookie from 'js-cookie';
import Router, { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import NProgress from 'nprogress';
import Script from 'next/script';
import ModalNotification from '../components/modalNotification';
import Toast from '../components/Toast';
import Branch from '../components/Branch';
import Orientation from '../components/Orientation';
import AppEffect from '../components/AppEffect';

import isMobile from '../helpers/isMobile';
import { GRASSDOOR, DRINKCANN, BENTO, KIKOKO, GOVANA } from '../constants/website';
import { socketConnect } from '../helpers/SocketHelper';
import getRouterPath from '../helpers/getRouterPath';
import '../services/interceptorsService';
import AppContext from '../Context/appContext';
import * as AppReducer from '../reducers/appReducer';

import appConfig from '../appConfig';
import * as COOKIE_VARIABLES from '../constants/cookieVariables';
import storageService from '../services/storageService';

import '../public/static/styles/app.scss';
import '../public/static/styles/websites/_notdefault.scss';
import '../public/static/styles/websites/_default.scss';
import '../public/static/styles/websites/_owned.scss';
import StaticFooter from '../components/DTC/StaticFooter';
import { DEFAULT_LOCATION } from '../constants/default';
import LoginDrawer from '../components/LoginDrawer';
import NewSidebar from '../components/NewSidebar';
import { isNewHomePageApplicable } from '../constants/feature';
import { Provider } from 'react-redux';
import store from '../redux/store';
import { JitsuProvider } from '@jitsu/jitsu-react';
import OneSignal from 'react-onesignal';

const SideBar = dynamic(() => import('../components/Sidebar'), { ssr: false });
const VersionChangeModal = dynamic(() => import('../components/modalNotification/VersionChangeModal'), { ssr: false });
const Footer = dynamic(() => import('../components/footer'), {
  ssr: false,
  loading: () => <StaticFooter />
});

if (typeof window !== 'undefined') {
  socketConnect();
}

NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', () => {
  window.routing = true;
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  window.routing = false;
  NProgress.done();
});

Router.events.on('routeChangeError', () => {
  window.routing = false;
  NProgress.done();
});

const MyApp = ({ Component, pageProps }) => {
  const [state, dispatch] = useReducer(AppReducer.reducer, AppReducer.initialState);
  const router = useRouter();
  const {
    initialLocationAddress = false,
    initialFreightLimit = false,
    initialPurchaseLimit = false,
    initialMarketingDetails = false,
    bannerData: initialBannerData = false,
    fullBannerData = false,
    initialUpsellProducts = false,
    initialDeliveryAddressDetails = false,
    fullCategoriesList: initialFullCategoriesList
  } = pageProps;

  const isCheckountOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';

  // const handleServiceWorkerRegistration = useCallback(() => {
  //   if ('serviceWorker' in navigator) {
  //     window.addEventListener('load', () => {
  //       navigator.serviceWorker.register('/service-worker.js');
  //     });
  //   }
  // }, []);

  const handleRouterReady = useCallback(() => {
    if (process.env.NEXT_PUBLIC_APP === BENTO) {
      const routerPath = [
        'blog',
        'contact-us.html',
        'faq.html',
        'order.html',
        'scheduled-menu.html',
        'privacy-policy.html',
        'terms--conditions.html',
        'where-bento-delivers.html',
        'new-next-day-only-menu.html',
        'referral-program.html'
      ];

      if (routerPath.includes(router.asPath.split('/')[1])) {
        router.push('/');
      }
    }

    const token = storageService.getToken();
    const localAddress = storageService.getDeliveryDetails();

    if (localAddress?.postcode && !cookie.get(COOKIE_VARIABLES.zipcode)) {
      cookie.set(COOKIE_VARIABLES.zipcode, localAddress.postcode, { expires: 30, sameSite: 'lax' });
    }

    if (localAddress?.placeId && !cookie.get(COOKIE_VARIABLES.placeId)) {
      cookie.set(COOKIE_VARIABLES.placeId, localAddress.placeId);
    }

    const currentCookieLat = cookie.get(COOKIE_VARIABLES.latitude);
    const currentCookieLng = cookie.get(COOKIE_VARIABLES.longitude);

    if (localAddress?.latitude && (!currentCookieLat || currentCookieLat === `${DEFAULT_LOCATION.lat}`)) {
      cookie.set(COOKIE_VARIABLES.latitude, localAddress.latitude, { expires: 30, sameSite: 'lax' });
    }

    if (localAddress?.longitude && (!currentCookieLng || currentCookieLng === `${DEFAULT_LOCATION.lng}`)) {
      cookie.set(COOKIE_VARIABLES.longitude, localAddress.longitude, { expires: 30, sameSite: 'lax' });
    }

    if (token) {
      const cookieOptions = { expires: 300, sameSite: isCheckountOnly ? 'none' : 'lax', secure: isCheckountOnly };
      cookie.set(COOKIE_VARIABLES.token, token, cookieOptions);

      storageService.removeToken();

      if (router.route === '/login') {
        window.location.href = `${window.location.origin}${router.query.redirect || '/'}`;
      } else {
        window.location.reload();
      }
    }

    const { positionID } = state;
    if (!positionID || router.route === '/product/[slug]') {
      return;
    }
    const splitArr = positionID.split('===');
    const productID = splitArr[0];
    const categoryID = splitArr.length > 1 ? splitArr[1] : null;
    if (categoryID) {
      const categoryElement = document.getElementById(categoryID);
      if (categoryElement) {
        // categoryElement.scrollIntoView();
      }
    }

    if (productID) {
      setTimeout(() => {
        const productElement = document.getElementById(productID);
        if (productElement) {
          // productElement.scrollIntoView();
        }
      }, 1000);
    }
  }, [router, state, isCheckountOnly]);

  const handleKikokoApp = useCallback(() => {
    if (process.env.NEXT_PUBLIC_APP === KIKOKO) {
      const widgetClass = document.getElementById('launcher');
      if (widgetClass) {
        const shouldHide = !['checkout', 'product', '', 'shop'].includes(getRouterPath(router.pathname));
        widgetClass.style.display = shouldHide ? 'none' : '';
      }
    }
  }, [router]);

  // useEffect(() => {
  //   handleServiceWorkerRegistration();
  // }, [handleServiceWorkerRegistration]);

  useEffect(() => {
    if (router.isReady) {
      handleRouterReady();
    }
  }, [router.isReady, handleRouterReady]);

  useEffect(() => {
    handleKikokoApp();
  }, [router, handleKikokoApp]);

  useEffect(() => {
    OneSignal.init({ appId: 'b1b59cab-eab3-459d-bc9a-f70b4a7eb744' })
  }, [])

  const domainName = pageProps?.host || pageProps?.domainName || storageService.getItemSessionParsed('domainName') || '';
  // const faviconPath = `${appConfig.FABICON_URL}/favicon.ico`;
  // const smallFaviconPath = `${appConfig.FABICON_URL}/favicon-16x16.png`;
  // const largeFaviconPath = `${appConfig.FABICON_URL}/favicon-32x32.png`;
  const faviconPath = `${appConfig.FABICON_URL}`;
  const smallFaviconPath = `${appConfig.FABICON_URL}`;
  const largeFaviconPath = `${appConfig.FABICON_URL}`;

  return (
    <SWRConfig value={{ shouldRetryOnError: false, dedupingInterval: 10000 }}>
      <JitsuProvider options={{ host: process.env.NEXT_PUBLIC_JITSU_HOST }}>
        <Provider store={store}>
          <AppContext.Provider value={{ state, dispatch }}>
            <>
              <meta name="google" content="notranslate" />
              <Head>
                <meta charSet="UTF-8" />
                <meta name="fragment" content="!" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black" />
                <meta name="Referrer-Policy" value="no-referrer | same-origin" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
                {process.env.NEXT_PUBLIC_APP !== GRASSDOOR && (
                  <link href="https://fonts.googleapis.com/css2?family=Lora:wght@700&display=swap" rel="stylesheet" />
                )}
                <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no" />
                <meta name="theme-color" content={appConfig.PRIMARY} />
                {process.env.NEXT_PUBLIC_APP === GRASSDOOR && <meta name="theme-color" content="#fff" />}
                <link rel="icon" href={faviconPath} />
                <link rel="icon" type="image/png" sizes="32x32" href={largeFaviconPath} />
                <link rel="icon" type="image/png" sizes="16x16" href={smallFaviconPath} />
                <link rel="apple-touch-icon" sizes="180x180" href={`${faviconPath}/apple-touch-icon.png`} />
                <link rel="mask-icon" href={`${faviconPath}/safari-pinned-tab.svg`} color={appConfig.PRIMARY} />
                {process.env.NEXT_PUBLIC_ENV !== 'production' && <meta name="robots" content="noindex,nofollow" />}
                {process.env.NEXT_PUBLIC_APP === GRASSDOOR && <meta name="robots" content="max-image-preview:large" />}
              </Head>
              {process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY && (
                <Script
                  src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}&callback=Function.prototype&v=3.exp&libraries=geometry,drawing,places`}
                  strategy="lazyOnload"
                />
              )}
              <Script src="https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver" strategy="lazyOnload" />
              <Script src="https://www.youtube.com/iframe_api" strategy="lazyOnload" />
              {!isCheckountOnly && (
                <Script id="acsb" dangerouslySetInnerHTML={{
                  __html: `(function(){ var s = document.createElement('script'), e = ! document.body ? document.querySelector('head') : document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ if(typeof acsbJS !== 'undefined'){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, language : 'en', position : 'left', leadColor : '#146ff8', triggerColor : '#146ff8', triggerRadius : '50%', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'medium', triggerOffsetX : 10, triggerOffsetY : 18, mobile : { triggerSize : 'small', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerOffsetX : 0, triggerOffsetY : 0, triggerRadius : '0px' } }); } }; e.appendChild(s);}());`
                }} />
              )}
              {(process.env.NEXT_PUBLIC_APP === GRASSDOOR || process.env.NEXT_PUBLIC_APP === DRINKCANN) &&
                process.env.NEXT_PUBLIC_ENV === 'production' && (
                  <Script src="https://www.dwin1.com/19038.js" strategy="lazyOnload" />
                )}
              {appConfig?.FRESHDESKWIDGETID ? (
                <>
                  <Script id="script-hideChatButton-onLoad" strategy="lazyOnload">
                    {`window.fcWidgetMessengerConfig = {
                      config: {
                        headerProperty:{
                          hideChatButton: true,
                        }
                      }
                    }`}
                  </Script>
                  <Script
                    defer
                    src="//in.fw-cdn.com/30836609/442809.js"
                    chat="true"
                    widgetId={appConfig?.FRESHDESKWIDGETID}
                    strategy="lazyOnload"
                  />
                  <Script id="script-configureChat" strategy="lazyOnload">
                    {`window.fwcrm?.on("widget:loaded", function () {
                      var userData=window.localStorage.getItem('userData');
                      const parseUserData=JSON.parse(userData);
                      if(parseUserData){
                        window.fcWidget.user.setFirstName(parseUserData?.name);
                        window.fcWidget.user.setPhone(parseUserData?.phone); 
                        window.fcWidget.setExternalId(parseUserData?.user_id);
                      }
                      window.fcWidget.user.setProperties({
                        "cf_brand":document.body.classList?.[1]
                      });
                    });`}
                  </Script>
                </>
              ) : (
                <>
                  <Script id="script-hideChatButton-onLoad" strategy="lazyOnload">
                    {`window.fcWidgetMessengerConfig = {
                      config: {
                        headerProperty:{
                          hideChatButton: true,
                        }
                      }
                    }`}
                  </Script>
                  <Script defer src="//in.fw-cdn.com/30836609/442809.js" chat="true" strategy="lazyOnload" />
                  <Script id="script-configureChat-nonWidgetId" strategy="lazyOnload">
                    {`window.fcSettings = {
                      onInit: function() {
                        window.fcWidget.on("widget:loaded", function(resp) {
                          const elementData = getComputedStyle(document.body);
                          const primary_variable = elementData?.getPropertyValue('--primary');
                          const secondary_variable = elementData?.getPropertyValue('--text-on-primary-color');
                          window.fcWidget.setConfig({
                            siteId: document.body.classList?.[1],
                            headerProperty: {
                              hideChatButton: true,
                              backgroundColor: primary_variable,
                              foregroundColor: secondary_variable
                            }
                          });
                          var userData = window.localStorage.getItem('userData');
                          const parseUserData = JSON.parse(userData);
                          if (parseUserData) {
                            window.fcWidget.user.setFirstName(parseUserData?.name);
                            window.fcWidget.user.setPhone(parseUserData?.phone); 
                            window.fcWidget.setExternalId(parseUserData?.user_id);
                          }
                          window.fcWidget.user.setProperties({
                            "cf_brand": document.body.classList?.[1]
                          });
                        });
                      }
                    };`}
                  </Script>
                </>
              )}
              {isNewHomePageApplicable ? (
                <NewSidebar
                  initialLocationAddress={initialLocationAddress}
                  initialFreightLimit={initialFreightLimit}
                  initialPurchaseLimit={initialPurchaseLimit}
                  initialUpsellProducts={initialUpsellProducts}
                  initialFullCategoriesList={initialFullCategoriesList}
                  initialDeliveryAddressDetails={initialDeliveryAddressDetails}
                />
              ) : (
                <SideBar
                  initialLocationAddress={initialLocationAddress}
                  initialFreightLimit={initialFreightLimit}
                  initialPurchaseLimit={initialPurchaseLimit}
                  initialUpsellProducts={initialUpsellProducts}
                  initialDeliveryAddressDetails={initialDeliveryAddressDetails}
                />
              )}
              <LoginDrawer
                initialLocationAddress={initialLocationAddress}
                initialFreightLimit={initialFreightLimit}
                initialPurchaseLimit={initialPurchaseLimit}
                initialUpsellProducts={initialUpsellProducts}
                initialDeliveryAddressDetails={initialDeliveryAddressDetails}
              />
              <Component {...pageProps} />
              {!isCheckountOnly && <Footer />}
              <ModalNotification initialDeliveryAddressDetails={initialDeliveryAddressDetails} domainName={domainName} />
              <Toast />
              <Branch
                initialLocationAddress={initialLocationAddress}
                initialFreightLimit={initialFreightLimit}
                initialPurchaseLimit={initialPurchaseLimit}
                initialUpsellProducts={initialUpsellProducts}
                initialDeliveryAddressDetails={initialDeliveryAddressDetails}
              />
              {isMobile() && <Orientation />}
              <AppEffect
                initialLocationAddress={initialLocationAddress}
                initialPurchaseLimit={initialPurchaseLimit}
                initialFreightLimit={initialFreightLimit}
                initialMarketingDetails={initialMarketingDetails}
                initialBannerData={initialBannerData}
                fullBannerData={fullBannerData}
                initialUpsellProducts={initialUpsellProducts}
                initialDeliveryAddressDetails={initialDeliveryAddressDetails}
                domainName={domainName}
              />
            </>
          </AppContext.Provider>
        </Provider>
      </JitsuProvider>
    </SWRConfig>
  );
};

export function reportWebVitals(metric) {
  // console.log('webvitals metrics', metric);
}

export default MyApp;
